import {
  getRestPath,
  getApprovalProcess,
  fillPunchRule,
  startFillPunchProcess,
  getMonthCardNumber,
  fillPunchDetail,
  completeFillPunchProcess,
  getVisibilityHolidayList,
  getAnnualLeave,
  startHolidayProcess,
  getLeaveDetail,
  getStaffAttendanceRule,
  getWhetherCanAskForLeave,
  getMonthHoliday,
  getOverTimeScope,
  getOverTimeVerify,
  getMonthOvertime,
  getOverTimeApprovalDetail,
  startOvertimeProcess,
  completeOvertimeProcess,
  getStandard,
  getProjectByContractId,
  getRateByProjectId,
  startTransferMin,
  getTransferProcessDetail,
  completeTransferMin,
  startLeaveProcess,
  getLeaveProcessDetail,
  completeTask,
  startProveProcessMin,
  proveProcessDetail,
  proveCompleteTaskMin,
  getStandardStaff,
  getEquMsg,
  startApplyEquipment
} from '@/services/approval';
import { ok, error } from '@/utils/modelUtils';

export default {
  namespace: 'newApproval',
  state: {},
  reducers: {},
  effects: {
    *getRestPath(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(getRestPath, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getApprovalProcess(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getApprovalProcess, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 补卡相关
    *fillPunchRule(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(fillPunchRule, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *startFillPunchProcess(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(startFillPunchProcess, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getMonthCardNumber(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getMonthCardNumber, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *fillPunchDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(fillPunchDetail, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *completeFillPunchProcess(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(completeFillPunchProcess, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 请假相关
    *getVisibilityHolidayList(action, { call }) {
      try {
        const res = yield call(getVisibilityHolidayList);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getAnnualLeave(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getAnnualLeave, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *startHolidayProcess(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(startHolidayProcess, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getLeaveDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getLeaveDetail, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getStaffAttendanceRule(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getStaffAttendanceRule, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getWhetherCanAskForLeave(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(getWhetherCanAskForLeave, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getMonthHoliday(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(getMonthHoliday, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getOverTimeScope(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getOverTimeScope, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getOverTimeVerify(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(getOverTimeVerify, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getMonthOvertime(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(getMonthOvertime, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getOverTimeApprovalDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getOverTimeApprovalDetail, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *startOvertimeProcess(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(startOvertimeProcess, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *completeOvertimeProcess(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(completeOvertimeProcess, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    //调动相关
    *getStandard(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getStandard, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getProjectByContractId(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getProjectByContractId, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getRateByProjectId(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getRateByProjectId, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *startTransferMin(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(startTransferMin, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getTransferProcessDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getTransferProcessDetail, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *completeTransferMin(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(completeTransferMin, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 离职相关
    *startLeaveProcess(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(startLeaveProcess, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 离职详情页
    *getLeaveProcessDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getLeaveProcessDetail, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 离职详情通过接口
    *completeTask(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(completeTask, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 证明开具 （发起接口）
    *startProveProcessMin(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(startProveProcessMin, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 证明开具详情接口
    *proveProcessDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(proveProcessDetail, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 证明开具通过接口
    *proveCompleteTaskMin(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(proveCompleteTaskMin, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 设备申领获取员工信息
    *getStandardStaff(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getStandardStaff, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 设备申领获取员工信息
    *getEquMsg(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getEquMsg, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    // 设备申领 发起接口
    *startApplyEquipment(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(startApplyEquipment, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
  }
};
