import request from '@/utils/request';

export async function getPresentFlowDetail(params) {
  return request('/setting/processNode/presentFlowDetail', { method: 'GET', params });
}

export async function updateFlowPriority(data) {
  return request('/setting/processNode/updateFlowPriority', { method: 'POST', data });
}

export async function updateOrAddConditionFlow(data) {
  return request('/setting/processNode/updateOrAddConditionFlow', { method: 'POST', data });
}

export async function processConditionDetail(params) {
  return request('/setting/processNode/processConditionDetail', { method: 'GET', params });
}

export async function getConditionParamList(params) {
  return request('/setting/processNode/getConditionParamList', { method: 'GET', params });
}

export async function releaseFlow(data) {
  return request('/setting/processNode/release', { method: 'POST', data });
}

export async function deleteFlow(data) {
  return request('/setting/processNode/deleteConditionFlow', { method: 'POST', data });
}

export async function addOrUpdateApproveCopyPeople(data) {
  return request('/setting/processNode/addOrUpdateApproveCopyPeople', { method: 'POST', data });
}

export async function selectCustomerList(params) {
  return request('/customer/selectCustomerList', { method: 'GET', params });
}

export async function getConditionValueList(params) {
  return request('/setting/processNode/getConditionValueList', { method: 'GET', params });
}

export async function updateConditionParam(data) {
  return request('/setting/processNode/updateConditionParam', { method: 'POST', data });
}

export async function deleteConditionParam(data) {
  return request('/setting/processNode/deleteConditionParam', { method: 'POST', data });
}

export async function saveFlowNode(data) {
  return request('/setting/processNode/updateFlowInformation', { method: 'POST', data });
}

/**
 * 获取当前运行流程与自定义流程的具体参数
 */
export async function getNewProcess(params) {
  return request('/setting/processNode/getNewProcess', { method: 'GET', params });
}
