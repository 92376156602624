import lodash from 'lodash';
import {
  getSettingsList,
  getSettingDetail,
  setSettingIcon,
  updateEnableState,
  updateApprovalSettings,
  getApprovalVisibleScope,
  updateApprovalVisibleScope,
  getApprovalByDefaultPeople,
  updateApprovalByDefaultPeople,
  getSettingLogList,
  getSettingLogOperationTypes
} from '@/services/approvalSetting';
import { getDataDictionary } from '@/utils/utils';
import { ok, error } from '@/utils/modelUtils';

export default {
  namespace: 'approvalSetting',
  state: {
    approvalGroup: []
  },
  reducers: {
    setApprovalGroup(state, action) {
      const data = action.payload;
      const newState = lodash.cloneDeep(state);
      newState.approvalGroup = data;
      return newState;
    }
  },
  effects: {
    *getApprovalGroup(action, { call, put }) {
      try {
        const res = yield call(getDataDictionary, 'WORKFLOW_GROUP');
        if (res && res.error_code === 200 && res.data) {
          yield put({ type: 'setApprovalGroup', payload: res.data });
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getSettingsList(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getSettingsList, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getSettingDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getSettingDetail, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *setSettingIcon(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(setSettingIcon, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *updateEnableState(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(updateEnableState, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *updateApprovalSetting(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(updateApprovalSettings, data);
        if (res && res.error_code === 200) {
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getApprovalVisibleScope(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getApprovalVisibleScope, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *updateApprovalVisibleScope(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(updateApprovalVisibleScope, data);
        if (res && res.error_code === 200) {
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getApprovalByDefaultPeople(action, { call }) {
      try {
        const res = yield call(getApprovalByDefaultPeople);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *updateApprovalByDefaultPeople(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(updateApprovalByDefaultPeople, data);
        if (res && res.error_code === 200) {
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getSettingLogList(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(getSettingLogList, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getSettingLogOperationTypes(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(getSettingLogOperationTypes, data);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    }
  }
};
