import lodash from 'lodash';
import request from '@/utils/request.js';
import {
  dicValTransform
} from '@/utils/utils';

export default {
  namespace: 'interactive',
  state: {
    // 控制客户面板显示与隐藏
    setClientShow: false,
    // 控制用户信息面板显示与隐藏
    userInfoShow: false,
    // 控制资源地图页面的切换
    resourceMapFlag: 1,
    // 控制地区负责人面板显示与隐藏
    areaClientShow: false,
    // 控制职场负责人的显示与隐藏
    workBannerShow: false,
    // 控制项目组信息面板显示与隐藏
    projectInfoShow: false,
    // 客户面板数据
    clientBanner: [],
    // 负责人信息
    chargePeopleInfo: {},
    // 配置客户负责人
    clientPersonal: [],
    // 配置客户负责人的下拉框数据
    clientPersonalSelect: {
      saleList: [],
      deliveryList: [],
      recruitment: [],
      businessList: [],
      relation: [],
      businessAssistant: []
    },
    // 当前客户的ID
    currentClientId: null,
    // 展开客户的ID 
    openClientId: null,
    // 地区面板数据
    areaBanner: [
      [],
      []
    ],
    // 配置地区负责人的下拉框数据
    areaSelect: {
      deliveryList: [],
      recruitment: [],
      relation: [],

    },
    // 配置地区负责人信息回显
    areaPersonal: [],
    // 当前地区
    currentArea: null,
    // 展开地区
    openArea: null,
    // 职场面板数据
    projectBanner: [
      [],
      []
    ],
    // 项目详情
    projectInfo: {},
    // 有费率的项目详情
    rateProjectInfo: {},
    // 人员地图数据
    peopleMap: [
      []
    ],
    // 标记职场项目新加和设置(1---新加   2---设置)
    workFlag: null,
    // 地区权限
    areaAuthority: {
      recruitment: [],
      deliveryList: [],
      relation: []
    },
    // 项目组权限
    projectAuth: {
      deliveryList: [],
      relation: [],
      saleManager: []
    },
    // 操作记录分页器
    opePage: {
      pageNum: 1,
      pageSize: 20,
      total: 0
    },
    // 操作记录表格数据
    opeRecord: [],
    // 人员地图默认展开的行
    defaultOpenRow: [],
    // 记录职场面板设置和新建(1--设置、2--新建)
    workBannerStatus: null,
    // 资源地图地区当前激活面板
    areaActiveCard: null,
    // 客户面板设置下拉框选择数据
    clientSelectDataState: [],
    // 当前客户
    currentClientState: {}
  },
  reducers: {
    currentClientStateReducers(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        currentClientState
      } = action.payload;
      newState.currentClientState = currentClientState;
      return newState;
    },
    clientSelectDataStateReducers(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        clientSelectDataState
      } = action.payload;
      newState.clientSelectDataState = clientSelectDataState;
      return newState;
    },
    setAreaActiveCard(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        areaActiveCard
      } = action.payload;
      newState.areaActiveCard = areaActiveCard;
      return newState;
    },
    clientShow(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        setClientShow
      } = action.payload;
      newState.setClientShow = setClientShow;
      return newState;
    },
    setUserInfoShow(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        userInfoShow
      } = action.payload;
      newState.userInfoShow = userInfoShow;
      return newState;
    },
    setResourceMapFlag(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        resourceMapFlag
      } = action.payload;
      newState.resourceMapFlag = resourceMapFlag;
      return newState;
    },
    setAreaClientShow(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        areaClientShow
      } = action.payload;
      newState.areaClientShow = areaClientShow;
      return newState;
    },
    setWorkBannerShow(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        workBannerShow
      } = action.payload;
      newState.workBannerShow = workBannerShow;
      return newState;
    },
    setProjectInfoShow(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        projectInfoShow
      } = action.payload;
      newState.projectInfoShow = projectInfoShow;
      return newState;
    },
    setClientBanner(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        clientBanner
      } = action.payload
      newState.clientBanner = clientBanner;
      return newState;
    },
    setChargePeopleInfo(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        chargePeopleInfo
      } = action.payload;
      newState.chargePeopleInfo = chargePeopleInfo;
      return newState;
    },
    setClientPersonal(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        clientPersonal
      } = action.payload;
      newState.clientPersonal = clientPersonal;
      return newState;
    },
    setClientPersonalSelect(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        clientPersonalSelect
      } = action.payload;

      let businessAssistant = []
      for (var x in clientPersonalSelect) {
        if (Array.isArray(clientPersonalSelect[x])) {
          businessAssistant.push(...clientPersonalSelect[x])
        }
      }
      clientPersonalSelect.businessAssistant = lodash.cloneDeep(businessAssistant)
      newState.clientPersonalSelect = clientPersonalSelect;
      return newState;
    },
    setCurrentClientId(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        currentClientId
      } = action.payload;
      newState.currentClientId = currentClientId;
      return newState;
    },
    setOpenClientId(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        openClientId
      } = action.payload;
      newState.openClientId = openClientId;
      return newState;
    },
    setAreaBanner(state, action) {
      let newState = lodash.cloneDeep(state);

      let {
        clientData,
        areaData
      } = action.payload;
      newState.areaBanner = [
        [],
        []
      ];
      newState.areaBanner[0].push(clientData);
      let rowIndex = 1;
      let colIndex = 0;
      areaData.forEach((item, index) => {
        if (newState.areaBanner[newState.areaBanner.length - 1].length == 4) {
          newState.areaBanner[newState.areaBanner.length] = [];
          rowIndex++;
          colIndex = 0;
        }
        item.id = `${rowIndex}${colIndex}`;
        newState.areaBanner[newState.areaBanner.length - 1].push(item);
        colIndex++;
      });

      return newState;
    },
    setAreaSelect(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        areaSelect
      } = action.payload;
      newState.areaSelect = areaSelect;
      return newState;
    },
    setAreaPersonal(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        areaPersonal
      } = action.payload;

      newState.areaPersonal = areaPersonal;
      return newState;
    },
    setCurrentArea(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        currentArea
      } = action.payload;
      newState.currentArea = currentArea;
      return newState;
    },
    setOpenArea(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        openArea
      } = action.payload;
      newState.openArea = openArea;
      return newState;
    },
    setProjectBanner(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        headData,
        bodyData
      } = action.payload;
      newState.projectBanner = [
        [],
        []
      ];

      newState.projectBanner[0] = headData;
      let rowIndex = 1;
      let colIndex = 0;
      bodyData.forEach((item, index) => {
        if (newState.projectBanner[newState.projectBanner.length - 1].length == 4) {
          newState.projectBanner[newState.projectBanner.length] = [];
          rowIndex++;
          colIndex = 0;
        }
        item.id = `${rowIndex}${colIndex}`;
        newState.projectBanner[newState.projectBanner.length - 1].push(item);
        colIndex++;
      });

      return newState;
    },
    setProjectInfo(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        projectInfo
      } = action.payload;
      newState.projectInfo = projectInfo;
      return newState;
    },
    setRateProjectInfo(state, action) {

      let newState = lodash.cloneDeep(state);
      let {
        rateProjectInfo
      } = action.payload;
      newState.rateProjectInfo = rateProjectInfo;
      return newState;
    },
    setPeopleMap(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        peopleMap
      } = action.payload;
      newState.peopleMap = peopleMap;
      return newState;
    },
    setWorkFlag(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        workFlag
      } = action.payload
      newState.workFlag = workFlag;
      return newState;
    },
    setAreaAuthority(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        areaAuthority
      } = action.payload;
      newState.areaAuthority = areaAuthority;
      return newState;
    },
    setProjectAuth(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        projectAuth
      } = action.payload;
      newState.projectAuth = projectAuth;
      return newState;
    },
    setOpePage(state, action) {
      let newState = lodash.cloneDeep(state);

      let {
        opePage
      } = action.payload;
      newState.opePage = opePage;
      return newState;
    },
    setOpeRecord(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        opeRecord
      } = action.payload;
      newState.opeRecord = opeRecord;
      return newState;
    },
    setDefaultOpenRow(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        defaultOpenRow
      } = action.payload;
      newState.defaultOpenRow = defaultOpenRow;
      return newState;
    },
    setWorkBannerStatus(state, action) {
      let newState = lodash.cloneDeep(state);
      let {
        workBannerStatus
      } = action.payload;
      newState.workBannerStatus = workBannerStatus;
      return newState;
    }
  },
  effects: {
    /*
     * 获取客户面板的所有客户信息
     */
    * clientBannerEffects(action, {
      put,
      call
    }) {
      let {
        data,
        error_code
      } = yield call(request.get, '/customer/passCustomer');

      let result = [];

      data.forEach((item, index) => {
        let emptyCharge = [];
        if (!item.businessManager) {
          emptyCharge.push('商务');
        }
        if (!item.deliveryManager) {
          emptyCharge.push('交付');
        }
        if (!item.recruitmentManager) {
          emptyCharge.push('招聘');
        }
        if (!item.relationshipManager) {
          emptyCharge.push('员工关系');
        }
        if (!item.salesManager) {
          emptyCharge.push('销售');
        }
        if (!item.businessAssistant) {
          emptyCharge.push('业务助理');
        }

        for (let key in item) {
          if (key == 'businessManager' || key == 'deliveryManager' || key == 'recruitmentManager') {
            if (item[key]) {
              if (key == 'businessManager') {
                item[key].job = `${item[key].staffName}-商务`;
              } else if (key == 'deliveryManager') {
                item[key].job = `${item[key].staffName}-交付`;
              } else if (key == 'recruitmentManager') {
                item[key].job = `${item[key].staffName}-招聘`;
              }
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
          if (key == 'relationshipManager') {
            if (item[key]) {
              item[key].job = `${item[key].staffName}-员工关系`;
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
          if (key == 'salesManager') {
            if (item[key]) {
              item[key].job = `${item[key].staffName}-销售`;
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
          if (key == 'businessAssistant') {
            if (item[key]) {
              item[key].job = `${item[key].staffName}-业务助理`;
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
        }

        result.push({
          id: index,
          finish: emptyCharge.length == 0 ? true : false,
          title: item.cstmFullName,
          demandCount: `${item.requiredQuantity ? item.requiredQuantity : 0}/${item.requiredCount ? item.requiredCount : 0}`,
          candidateCount: item.candidateQuantity ? item.candidateQuantity : 0,
          workCount: item.workQuantity,
          waitCount: item.pendingEntryQuantity,
          charge: [{
            salesManager: item.salesManager
          }, {
            deliveryManager: item.deliveryManager
          }, {
            recruitmentManager: item.recruitmentManager
          }, {
            businessManager: item.businessManager
          }, {
            relationshipManager: item.relationshipManager
          }, {
            businessAssistant: item.businessAssistant
          }],
          clientCount: item.regionCount ? item.regionCount : 0,
          emptyCharge: emptyCharge,
          customerId: item.customerId,
          personRole: item.personRole
        });
      });

      yield put({
        type: 'setClientBanner',
        payload: {
          clientBanner: result
        }
      });
    },
    /*
     * 获取客户面板的负责人信息
     */
    * getClientBannerUserInfoEffects(action, {
      call,
      put
    }) {
      let {
        staffId
      } = action.payload;
      let {
        data
      } = yield call(request.get, '/staff/getStaffInfoById', {
        params: {
          staffId
        }
      });
      yield put({
        type: 'setChargePeopleInfo',
        payload: {
          chargePeopleInfo: {
            birthday: data.birthday ? data.birthday : '--',
            departmentName: data.deptName ? data.deptName : '--',
            dutyName: data.staffJobName ? data.staffJobName : '--',
            housePhone: data.housePhone ? data.housePhone : '--',
            joinTime: data.joinTime ? data.joinTime : '--',
            email: data.email ? data.email : '--',
            phoneNum: data.phoneNum ? data.phoneNum : '--',
            projectDeptName: data.deptName ? data.deptName : '--',
            sex: data.sex ? '女' : (data.sex == 0 ? '男' : '--'),
            staffId: data.staffId,
            staffName: data.staffName ? data.staffName : '--',
            staffNumber: data.staffNumber ? data.staffNumber : '--',
            wechat: data.wechat ? data.wechat : '--',
            img: data.img,
            sysUserHead: data.sysUserHead,
          }
        }
      });
    },
    /*
     * 获取配置客户负责面板的下拉框数据
     */
    * clientPersonalSelectEffects(action, {
      call,
      put
    }) {
      let {
        data
      } = yield call(request.get, '/customer/getRelation');
      yield put({
        type: 'setClientPersonalSelect',
        payload: {
          clientPersonalSelect: data
        }
      });
    },
    /*
     * 修改客户负责人
     */
    * updateClientPersonalEffects(action, {
      call,
      put
    }) {
      let {
        businessManager,
        customerId,
        deliveryManager,
        oldBusinessManager,
        oldDeliveryManager,
        oldRecruitmentManager,
        oldRelationshipManager,
        oldSalesManagerName,
        recruitmentManager,
        relationshipManager,
        salesManagerName,
        callBack,
        businessAssistant
      } = action.payload;
      let {        
        error_code,
        error_msg
      } = yield call(request.post, '/customer/UpdateCustomerMan', {
        data: {
          businessManager,
          customerId,
          deliveryManager,
          oldBusinessManager,
          oldDeliveryManager,
          oldRecruitmentManager,
          oldRelationshipManager,
          oldSalesManagerName,
          recruitmentManager,
          relationshipManager,
          salesManagerName,
          businessAssistant
        }
      });
      callBack({
        code: error_code,
        msg: error_msg
      });
    },
    /*
     * 获取客户对应的地区数据
     */
    * byClientIdGetAreaEffects(action, {
      call,
      put
    }) {
      let {
        customerId,
        clientData,
        callBack
      } = action.payload;
      let {
        data
      } = yield call(request.get, '/workPlace/passArea/' + customerId);
      let result = [];
      data.dataList.forEach((item, index) => {
        let emptyCharge = [];
        if (!item.deliveryManager) {
          emptyCharge.push('交付');
        }
        if (item.recruList.length == 0) {
          emptyCharge.push('招聘团队');
        }
        if (!item.relationshipManager) {
          emptyCharge.push('员工关系');
        }

        for (let key in item) {
          if (key == 'deliveryManager') {
            if (item[key]) {
              item[key].job = `${item[key].staffName}-交付`;
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
          if (key == 'relationshipManager') {
            if (item[key]) {
              item[key].job = `${item[key].staffName}-员工关系`;
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
        }
        let areaDes = dicValTransform(item.areaName);
        result.push({
          finish: emptyCharge.length == 0 ? true : false,
          title: areaDes,
          areaKey: item.workplaceAreaKey,
          demandCount: `${item.requiredQuantity ? item.requiredQuantity : 0}/${item.requiredCount ? item.requiredCount : 0}`,
          candidateCount: item.candidateQuantity ? item.candidateQuantity : 0,
          workCount: item.workQuantity ? item.workQuantity : 0,
          waitCount: item.pendingEntryQuantity ? item.pendingEntryQuantity : 0,
          charge: [{
            deliveryManager: item.deliveryManager
          }, {
            relationshipManager: item.relationshipManager
          }, {
            recruList: item.recruList
          }],
          clientCount: item.placeCount ? item.placeCount : 0,
          emptyCharge: emptyCharge,
          customerId: item.customerId
        });
      });
      let le = [];
      let ri = [];

      result.forEach(item => {
        if (item.emptyCharge.length !== 0) {
          ri.push(item);
        } else {
          le.push(item);
        }
      });

      yield put({
        type: 'setAreaBanner',
        payload: {
          areaData: [...le, ...ri],
          clientData
        }
      });
      yield put({
        type: 'setAreaAuthority',
        payload: {
          areaAuthority: data.dutymap
        }
      });
      yield put({
        type: 'setProjectBanner',
        payload: {
          headData: [],
          bodyData: []
        }
      })
      callBack();
    },
    /*
     * 获取配置地区负责面板的下拉框数据
     */
    * clientAreaSelectEffects(action, {
      call,
      put
    }) {
      let {
        customerId
      } = action.payload;
      let {
        data
      } = yield call(request.get, '/workPlace/getRelation/' + customerId);

      yield put({
        type: 'setAreaSelect',
        payload: {
          areaSelect: {
            deliveryList: data.deliveryList,
            recruitment: lodash.flatMapDeep(data.recruitment),
            relation: data.relation
          }
        }
      });
    },
    /*
     * 点击配置地区负责人
     */
    * areaPersonalSetEffects(action, {
      call,
      put
    }) {
      let {
        deliveryManager,
        oldDeliveryManager,
        relationshipManager,
        oldRelationshipManager,
        oldRecrus,
        customerId,
        areaName,
        callBack
      } = action.payload;
      let {
        data
      } = yield call(request.post, '/workPlace/UpdateAreaMan', {
        data: {
          deliveryManager,
          oldDeliveryManager,
          relationshipManager,
          oldRelationshipManager,
          oldRecrus,
          customerId,
          areaName,
        }
      });
      callBack(data);
    },
    /*
     * 获取所有项目的信息
     */
    * byClientIdAndAreaNameGetWorkEffects(action, {
      call,
      put
    }) {
      let {
        headData,
        customerId,
        workArea
      } = action.payload;

      let {
        data
      } = yield call(request.get, '/project/listProject', {
        params: {
          customerId,
          workArea
        }
      });

      let result = [];

      data.dataList.forEach((item, index) => {
        let emptyCharge = [];

        if (item.delitionInfo) {
          if (!(item.delitionInfo && item.delitionInfo.code !== 0)) {
            emptyCharge.push('交付');
          }
        } else {
          emptyCharge.push('交付');
        }
        if (!item.relationInfo) {
          emptyCharge.push('员工关系');
        }

        for (let key in item) {
          if (key == 'delitionInfo') {
            if (item[key]) {
              if (item[key].code !== 0) {
                item[key].job = `${item[key].staffName}-交付`;
              } else {
                item[key].job = '';
              }
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
          if (key == 'relationInfo') {
            if (item[key]) {
              item[key].job = `${item[key].staffName}-员工关系`;
            } else {
              item[key] = {};
              item[key].job = '';
            }
          }
        }

        result.push({
          finish: emptyCharge.length == 0 ? true : false,
          workTitle: item.workPlaceName,
          projectTitle: item.projectName,
          demandCount: `${item.requiredQuantity ? item.requiredQuantity : 0}/${item.requiredCount ? item.requiredCount : 0}`,
          candidateCount: item.candidateQuantity ? item.candidateQuantity : 0,
          workCount: item.workQuantity ? item.workQuantity : 0,
          waitCount: item.pendingEntryQuantity ? item.pendingEntryQuantity : 0,
          charge: [{
            delitionInfo: item.delitionInfo
          }, {
            relationInfo: item.relationInfo
          }],
          emptyCharge: emptyCharge,
          projectId: item.projectId,
          workplaceId: item.workplaceId
        });
      });

      let le = [];
      let ri = [];

      result.forEach(item => {
        if (item.emptyCharge.length !== 0) {
          ri.push(item);
        } else {
          le.push(item);
        }
      });

      yield put({
        type: 'setProjectBanner',
        payload: {
          headData,
          bodyData: [...le, ...ri]
        }
      });

      yield put({
        type: 'setProjectAuth',
        payload: {
          projectAuth: data.dutyList
        }
      });
    },
    /*
     * 通过项目ID获取项目详情
     */
    * byProjectIdGetInfoEffects(action, {
      call,
      put
    }) {
      let {
        id
      } = action.payload;
      let {
        data
      } = yield call(request.get, '/project/getProjectById', {
        params: {
          projectId: id
        }
      });
      yield put({
        type: 'setProjectInfo',
        payload: {
          projectInfo: data
        }
      });
    },
    /*
     * 判断职场项目有无费率
     */
    * workProjectIsHaveRateEffects(action, {
      call,
      put
    }) {
      let {
        workplaceId,
        callBack
      } = action.payload;
      let {
        error_code,
        data
      } = yield call(request.get, '/project/isRateWorkplace', {
        params: {
          workplaceId
        }
      });

      if (error_code !== 10038) {
        yield put({
          type: 'setRateProjectInfo',
          payload: {
            rateProjectInfo: data
          }
        });
      }
      callBack(error_code);
    },
    /*
     * 职场项目的新增
     */
    * workBannerInsertEffects(action, {
      call,
      put
    }) {
      let {
        projectName,
        deliveryManager,
        relationshipManager,
        workplaceName,
        detailedAddress,
        latitudeLongitude,
        introduce,
        workplaceId,
        projectId,
        callBack
      } = action.payload;
      let {
        data,
        error_code,
        error_msg
      } = yield call(request.post, '/project/addProjectTeam', {
        data: {
          projectName,
          deliveryManager,
          relationshipManager,
          workplaceName,
          detailedAddress,
          latitudeLongitude,
          introduce,
          workplaceId,
          projectId
        }
      });
      callBack(error_code, error_msg);
    },
    /*
     * 人员地图
     */
    * peopleMapEffects(action, {
      call,
      put
    }) {
      let {
        data
      } = yield call(request.get, '/map/list');
      yield put({
        type: 'setPeopleMap',
        payload: {
          peopleMap: data
        }
      });
    },
    /*
     * 设置职场项目
     */
    * updateWorkProjectEffects(action, {
      call,
      put
    }) {
      let {
        projectId,
        workplaceId
      } = action.payload;
      let {
        data
      } = yield call(request.get, '/project/getRelation', {
        params: {
          projectId,
          workplaceId
        }
      });
      yield put({
        type: 'setRateProjectInfo',
        payload: {
          rateProjectInfo: data
        }
      });
    },
    /*
     * 通过职场ID获取职场信息
     */
    * byWorkplaceIdGetInfo(action, {
      call,
      put
    }) {
      let {
        workplaceId
      } = action.payload;
      let {
        data
      } = yield call(request.get, '/project/getAddInfo', {
        params: {
          workplaceId
        }
      });
      yield put({
        type: 'setProjectInfo',
        payload: {
          projectInfo: data
        }
      });
    },
    /*
     * 地图组件中的搜索
     */
    * mapSearchEffects(action, {
      call,
      put
    }) {

    },
    /*
     * 获取操作记录表格数据
     */
    * operationRecordEffects(action, {
      call,
      put,
      select
    }) {
      let {
        opePage
      } = action.payload;
      let {
        data
      } = yield call(request.get, '/customer/getResourceContractLog', {
        params: {
          pageNum: opePage.pageNum,
          pageSize: opePage.pageSize
        }
      });

      yield put({
        type: 'setOpeRecord',
        payload: {
          opeRecord: data.records
        }
      });
      let pageInfo = yield select(({
        interactive
      }) => interactive.opePage);
      pageInfo.total = data.total;
      yield put({
        type: 'setOpePage',
        payload: {
          opePage: pageInfo
        }
      });

      return Promise.resolve('Hello');
    },
    /*
     * 获取资源地图配置客户面板的下拉框数据 
     */
    * getClientSelectDataEffects(action, {
      call,
      put
    }) {
      let res = yield call(request.get, '/customer/getUserRelation');
      yield put.resolve({
        type: 'clientSelectDataStateReducers',
        payload: {
          clientSelectDataState: res.data
        }
      });
      return Promise.resolve(res.error_code);
    }
  }
}
