/*常用公共数据处理方法*/
import { connect } from 'dva'
import { Tag } from 'antd'
import React from 'react'
import request from '@/utils/request';

@connect(({ user, buttonsAuthList }) => ({
  user,
  buttonsAuthList
}))
class PublicUtils {

  /**
   * 获取下拉数据的label值
   * @param1 value //下拉枚举的value，根据value 返回label
   * @param2 enumArr //下拉枚举数组{label:,value:}
   * @param3 showValue //true当没有匹配到value时默认返回value;false返回空
   * return  label 返回匹配到value 的label
   */
  static getSelectLabel(value, enumArr, showValue) {
    let res = showValue !== false ? value : '';
    for (let item of enumArr) {
      if (item.value === value) {
        res = item.label;
        break
      }
    }
    return res;
  }

  /**
    * 获取全部数据字典数据
    */
  static async dataDictionary() {
    const res = await request.get('/sysDictionary/getDictList')
    window.localStorage.setItem('dictionary', JSON.stringify(res.data) ?? null);
  }

  /**
     * 获取单独数据字典数据
     */
  static getDictionary(key) {
    let dictionary = JSON.parse(window.localStorage.getItem('dictionary'));
    let arr = []
    dictionary && dictionary.map((res, index) => {
      if (res.dictName == key) {
        return arr = res.dictValueList;
      }
    });
    return arr;
  }

  /**
   * 转换数据字典值
   * @param1 val //要转换的值
   * @param2 arr //数据字典数组
   * @param3 type //true为名字转值，false为值转名字
   */
  static getDictionaryValue(val, arr, type) {
    let label = '';
    let labelList = '';
    let list = arr || [];
    if (!type) {
      labelList = list.find(item => item.dictValue == val);
      label = (labelList && labelList.dictValueDesc) || val
    } else {
      labelList = list.find(item => item.dictValueDesc == val);
      label = (labelList && labelList.dictValue) || val
    }
    return label
  }

  // 员工类别
  static getStaffType(status, type) {
    if (type) {
      let list = [
        { dictValue: '0', dictValueDesc: '本部' },
        { dictValue: '1', dictValueDesc: '项目' },
        { dictValue: '2', dictValueDesc: '顾问' },
        { dictValue: '3', dictValueDesc: '实习' },
        { dictValue: '4', dictValueDesc: '退休返聘' }
      ];
      return list
    }

    let statusText = '';
    switch (status) {
      case 0:
        statusText = '本部'
        break
      case 1:
        statusText = '项目'
        break
      case 2:
        statusText = '顾问'
        break
      case 3:
        statusText = '实习'
        break
      case 4:
        statusText = '退休返聘'
        break
      default:
        statusText = '-'
        break
    }
    return statusText
  }



  /**
   * 检查用户权限
   * @param1 code //要检查的权限code
   * @param2 type //预留》默认button权限
   * return  有对应权限则返回true,没有返回false
   */
  // static checkAuth(code,type){
  //   let authList = !window.localStorage.getItem('buttonsAuthList') ? [] : JSON.parse(window.localStorage.getItem('buttonsAuthList'));
  //   if(!(authList&&Object.keys(authList).length)){
  //     //console.log('没有权限')
  //     return
  //   }
  //   return Object.keys(authList).indexOf(code)>-1;
  // }

  /**
   * 检查用户权限
   * @param1 code //要检查的权限code
   * return  有对应权限则返回true,没有返回false
   */
  static checkAuth(code) {
    let permissions = JSON.parse(window.localStorage.getItem('permissions'));
    return permissions.includes(code);
  }

  /**
   * 公共需求状态按钮样式
   * @param1 code //状态
   * @param2 type //预留》默认button权限
   * return  有对应权限则返回true,没有返回false
   */
  static getNeedsStatus(status) {
    //let status = demandDetail.restartTimes > 0 ? 6 : statusOrigin
    let obj = {
      0: { color: '#999999', text: '未澄清' },
      1: { color: '#3CD1D1', text: '澄清有效' },
      3: { color: '#FF3300', text: '已关闭' },
      4: { color: '#FF3300', text: '超时关闭' },
      5: { color: '#1890FF', text: '已满足' },
      6: { color: '#FF3300', text: '澄清无效' },
      7: { color: '#FF9933', text: '重启需求' },
    }
    return <Tag color={obj[status] ? obj[status].color : '#999999'}>{obj[status] ? obj[status].text : '未澄清'}</Tag>
  }
  /**
   * 公共数据状态枚举
   */
  static getDataScopeStatus(status) {
    let obj = {
      1: '全部数据',
      2: '本部门及以下数据权限',
      3: '仅本人数据',
      4: '指定范围数据',
      5:'指定部门及下属部门数据'
    }
    let list = [
      { value: 1, label: '全部数据' },
      { value: 2, label: '本部门及以下数据权限' },
      { value: 3, label: '仅本人数据' },
      { value: 4, label: '指定范围数据' },
      { value: 5, label: '指定部门及下属部门数据' },
    ]
    return !status ? list : obj[status]
  }
}
export default PublicUtils

