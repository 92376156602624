import lodash from 'lodash';
import request from '@/utils/request.js';
import moment from 'moment';
import { getDataDictionary } from '@/utils/utils';

function theWeek() {
  let totalDays = 0;
  let now = new Date();
  let years = now.getYear();
  if (years < 1000) years += 1900;
  let days = new Array(12);
  days[0] = 31;
  days[2] = 31;
  days[3] = 30;
  days[4] = 31;
  days[5] = 30;
  days[6] = 31;
  days[7] = 31;
  days[8] = 30;
  days[9] = 31;
  days[10] = 30;
  days[11] = 31;
  //判断是否为闰年，针对2月的天数进行计算
  if (Math.round(now.getYear() / 4) == now.getYear() / 4) {
    days[1] = 29;
  } else {
    days[1] = 28;
  }
  if (now.getMonth() == 0) {
    totalDays = totalDays + now.getDate();
  } else {
    let curMonth = now.getMonth();
    for (let count = 1; count <= curMonth; count++) {
      totalDays = totalDays + days[count - 1];
    }
    totalDays = totalDays + now.getDate();
  }
  //得到第几周
  let week = Math.round(totalDays / 7);
  return week;
}

function countCurrentDayTime(v) {
  let { attendanceAmStart, attendanceAmEnd, attendancePmStart, attendancePmEnd } = v;
  attendanceAmStart = `${moment().format('YYYY-MM-DD')} ${attendanceAmStart}`;
  attendanceAmEnd = `${moment().format('YYYY-MM-DD')} ${attendanceAmEnd}`;
  attendancePmStart = `${moment().format('YYYY-MM-DD')} ${attendancePmStart}`;
  attendancePmEnd = `${moment().format('YYYY-MM-DD')} ${attendancePmEnd}`;
  let timeAm = moment(attendanceAmEnd).diff(moment(attendanceAmStart), 'hours');
  let timePm = moment(attendancePmEnd).diff(moment(attendancePmStart), 'hours');
  return timeAm + timePm;
}

export default {
  namespace: 'approval',
  state: {
    // 审批设置基本信息显示与隐藏
    basicInfoVisible: false,
    // 审批设置可见人显示与已隐藏
    seePeopleVisible: false,
    // 审批设置操作记录显示与隐藏
    operationLogVisible: false,
    // 审批详情显示与隐藏
    approvalDetailVisible: false,
    // 证明开具显示于隐藏
    proveThatOpensDetailVisible: false,
    equipmentDetailVisible: false,
    leavaDetailVisible: false,
    // 审批记录转交显示与隐藏
    careOfVisible: false,
    // 获取当前时间在一年中是第几周
    currentWeek: (() => {
      let week = theWeek() + 1;
      return `${new Date().getFullYear()}-${week.toString()}`;
    })(),
    currentMonth: (() => {
      return `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
    })(),
    // 表示周和月(1---周、2---月)
    timeFlag: 1,
    // 交互计划表周数据
    planTableWeek: [],
    // 交互计划表提交、查看、审核Drawer显示与隐藏
    planDraweVisible: false,
    // 交互计划表操作状态(0--提交计划、1--查看计划、2--审核)
    planOperationStatus: null,
    // 交互计划表当前操作的行
    planCurrentRow: {},
    // 提交计划日志
    submitLog: [],
    // 交付计划表周数据过滤
    planTableWeekDataFilter: [],
    // 审批设置表格数据
    settingsList: [],
    // 审批设置表格分页信息
    approvalTablePageInfo: {
      pageNum: 1,
      pageSize: 20,
      visibleStatus: 1,
      approvalName: null,
      approvalEnable: null,
      total: 0,
      approvalGroupBy: null
    },
    // 审批设置当前行数据
    currentRowApproval: {},
    // 可见人表格数据
    seePeopelTable: [],
    // 可见人下拉部门数据
    seePeopleSelectTree: [],
    // 操作记录表格数据
    operationLogTable: [],
    // 控制流程设置显示与隐藏
    signatureVisible: false,
    flowSettingVisible: false,
    // 流程设置
    flowSettingData: [],
    // 普通签(0)、会签(2)、或签(1)
    flowCategory: null,
    //  普通签、会签、或签数据列表
    flowCategoryList: [],
    // schemeId
    schemeId: null,
    // 抄送人
    copyStaffName: [],
    // 抄送人ID
    copyStaffId: [],
    settingsIdCopy: null,
    schemeName: '',
    priority: null,
    // 数据字典
    dataDictionaries: [],
    // 标记需求标题
    demandFlag: 1,
    // 审配tabs切换标记
    approvalFlag: 1,
    // 审批下拉选择框数据
    approvalSelectData: [],
    // 审批数据
    approvalData: [],
    recruitmentProjectTable: null,
    approvalCount: 0,
    // 标记我的审批和审批记录(0--待我审批、1--我已审批、2--我发起的、3--抄送给我、4--待审批、5--已结束)
    myApprovalOrApprovalLog: null,
    // 我的审批提交人
    myApprovalSubmitPeople: [],
    // 审批详情
    approvalDetailBanner: {
      mainInfo: {},
      process: [],
      baseInfo: {},
      recipients: []
    },
    // 审批流程节点信息
    approvalFlowNodeInfo: [],
    approvalBannerShow: false,
    // 审批设置基本信息图标
    approvalBasicInfoIcon: {},
    // 所有的假期
    vacationList: [],
    // 审批附件
    applyAttachment: [],
    // 控制转交人Modal显示与隐藏
    transformPeopleFlag: false,
    // 项目组信息
    projectInfo: [],
    // 证明开具
    proveThatOpensDetail: [],

    proveThatOpensDetailShow: false,
    // 离职详情
    leavaDetail: {},
    // 离职审批流程
    leaveDetailBanner: {
      process: [],
      recipients: []
    },
    // 设备申领详情
    equipmentDetail: {},
    // 设备申领流程
    equipmentDetailBanner: {
      process: [],
      recipients: []
    },
    // 考勤时间
    attendanceTimes: null,
    // 考勤分段数据
    attendanceData: [],
    // 银行列表
    bankList: []
  },

  reducers: {
    setBankList(state, action) {
      let newState = lodash.cloneDeep(state);
      let { bankList } = action.payload;
      newState.bankList = bankList;
      return newState;
    },
    setAttendanceData(state, action) {
      let newState = lodash.cloneDeep(state);
      let { attendanceData } = action.payload;
      newState.attendanceData = attendanceData;
      return newState;
    },
    setAttendanceTimes(state, action) {
      let newState = lodash.cloneDeep(state);
      let { attendanceTimes } = action.payload;
      newState.attendanceTimes = attendanceTimes;
      return newState;
    },
    setProveThatOpensDetail(state, action) {
      let newState = lodash.cloneDeep(state);
      let { proveThatOpensDetail } = action.payload;
      newState.proveThatOpensDetail = proveThatOpensDetail;
      return newState;
    },
    setProjectInfo(state, action) {
      let newState = lodash.cloneDeep(state);
      let { projectInfo } = action.payload;
      newState.projectInfo = projectInfo;
      return newState;
    },
    setTransformPeopleFlag(state, action) {
      let newState = lodash.cloneDeep(state);
      let { transformPeopleFlag } = action.payload;
      newState.transformPeopleFlag = transformPeopleFlag;
      return newState;
    },
    setApplyAttachment(state, action) {
      let newState = lodash.cloneDeep(state);
      let { applyAttachment } = action.payload;
      newState.applyAttachment = applyAttachment;
      return newState;
    },
    setLeavaDetailEffects(state, action) {
      let newState = lodash.cloneDeep(state);
      let { leavaDetail } = action.payload;
      newState.leavaDetail = leavaDetail;
      return newState;
    },
    setEquipmentDetailEffects(state, action) {
      let newState = lodash.cloneDeep(state);
      let { equipmentDetail } = action.payload;
      newState.equipmentDetail = equipmentDetail;
      return newState;
    },
    setVacationList(state, action) {
      let newState = lodash.cloneDeep(state);
      let { vacationList } = action.payload;
      newState.vacationList = vacationList;
      return newState;
    },
    setApprovalBasicInfoIcon(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalBasicInfoIcon } = action.payload;
      newState.approvalBasicInfoIcon = approvalBasicInfoIcon;
      return newState;
    },
    setApprovalBannerShow(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalBannerShow } = action.payload;
      newState.approvalBannerShow = approvalBannerShow;
      return newState;
    },
    setBasicInfoVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { basicInfoVisible } = action.payload;
      newState.basicInfoVisible = basicInfoVisible;
      return newState;
    },
    setSeePeopleVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { seePeopleVisible } = action.payload;
      newState.seePeopleVisible = seePeopleVisible;
      return newState;
    },
    setOperationLogVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { operationLogVisible } = action.payload;
      newState.operationLogVisible = operationLogVisible;
      return newState;
    },
    setApprovalDetailVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalDetailVisible } = action.payload;
      newState.approvalDetailVisible = approvalDetailVisible;
      return newState;
    },
    setLeavaDetailVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { leavaDetailVisible } = action.payload;
      newState.leavaDetailVisible = leavaDetailVisible;
      return newState;
    },
    setEquipmentDetailVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { equipmentDetailVisible } = action.payload;
      newState.equipmentDetailVisible = equipmentDetailVisible;
      return newState;
    }, // 证明开具
    setproveThatOpensDetailVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { proveThatOpensDetailVisible } = action.payload;
      newState.proveThatOpensDetailVisible = proveThatOpensDetailVisible;
      return newState;
    },
    setLeavaDetailVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { leavaDetailVisible } = action.payload;
      newState.leavaDetailVisible = leavaDetailVisible;
      return newState;
    },
    setEquipmentDetailVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { equipmentDetailVisible } = action.payload;
      newState.equipmentDetailVisible = equipmentDetailVisible;
      return newState;
    },
    setCareOfVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { careOfVisible } = action.payload;
      newState.careOfVisible = careOfVisible;
      return newState;
    },
    setCurrentWeek(state, action) {
      let newState = lodash.cloneDeep(state);
      let { currentWeek } = action.payload;
      newState.currentWeek = currentWeek;
      return newState;
    },
    setCurrentMonth(state, action) {
      let newState = lodash.cloneDeep(state);
      let { currentMonth } = action.payload;
      newState.currentMonth = currentMonth;
      return newState;
    },
    setTimeFlag(state, action) {
      let newState = lodash.cloneDeep(state);
      let { timeFlag } = action.payload;
      newState.timeFlag = timeFlag;
      return newState;
    },
    setPlanTableWeek(state, action) {
      let newState = lodash.cloneDeep(state);
      let { planTableWeek } = action.payload;
      newState.planTableWeek = planTableWeek;
      return newState;
    },
    setPlanDraweVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { planDraweVisible } = action.payload;
      newState.planDraweVisible = planDraweVisible;
      return newState;
    },
    setPlanOperationStatus(state, action) {
      let newState = lodash.cloneDeep(state);
      let { planOperationStatus } = action.payload;
      newState.planOperationStatus = planOperationStatus;
      return newState;
    },
    setPlanCurrentRow(state, action) {
      let newState = lodash.cloneDeep(state);
      let { planCurrentRow } = action.payload;
      newState.planCurrentRow = planCurrentRow;
      return newState;
    },
    setSubmitLog(state, action) {
      let newState = lodash.cloneDeep(state);
      let { submitLog } = action.payload;
      newState.submitLog = submitLog;
      return newState;
    },
    setPlanTableWeekDataFilter(state, action) {
      let newState = lodash.cloneDeep(state);
      let { planTableWeekDataFilter } = action.payload;
      newState.planTableWeekDataFilter = planTableWeekDataFilter;
      return newState;
    },
    setSettingsList(state, action) {
      let newState = lodash.cloneDeep(state);
      let { settingsList } = action.payload;
      newState.settingsList = settingsList;
      return newState;
    },
    setApprovalTablePageInfo(state, action) {
      let newState = lodash.cloneDeep(state);
      newState.approvalTablePageInfo = action.payload;
      return newState;
    },
    setCurrentRowApproval(state, action) {
      let newState = lodash.cloneDeep(state);
      let { currentRowApproval } = action.payload;
      newState.currentRowApproval = currentRowApproval;
      return newState;
    },
    setSeePeopelTable(state, action) {
      let newState = lodash.cloneDeep(state);
      let { seePeopelTable } = action.payload;
      newState.seePeopelTable = seePeopelTable;
      return newState;
    },
    setSeePeopleSelectTree(state, action) {
      let newState = lodash.cloneDeep(state);
      let { seePeopleSelectTree } = action.payload;
      newState.seePeopleSelectTree = seePeopleSelectTree;
      return newState;
    },
    setOperationLogTable(state, action) {
      let newState = lodash.cloneDeep(state);
      let { operationLogTable } = action.payload;
      newState.operationLogTable = operationLogTable;
      return newState;
    },
    setSignatureVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { signatureVisible } = action.payload;
      newState.signatureVisible = signatureVisible;
      return newState;
    },
    setFlowSettingVisible(state, action) {
      let newState = lodash.cloneDeep(state);
      let { flowSettingVisible } = action.payload;
      newState.flowSettingVisible = flowSettingVisible;
      return newState;
    },
    setFlowSettingData(state, action) {
      let newState = lodash.cloneDeep(state);
      let { flowSettingData } = action.payload;
      newState.flowSettingData = flowSettingData;
      return newState;
    },
    setFlowCategory(state, action) {
      let newState = lodash.cloneDeep(state);
      let { flowCategory } = action.payload;
      newState.flowCategory = flowCategory;
      return newState;
    },
    setFlowCategoryList(state, action) {
      let newState = lodash.cloneDeep(state);
      let { flowCategoryList } = action.payload;
      newState.flowCategoryList = flowCategoryList;
      return newState;
    },
    setSchemeId(state, action) {
      let newState = lodash.cloneDeep(state);
      let { schemeId } = action.payload;
      newState.schemeId = schemeId;
      return newState;
    },
    setCopyStaffName(state, action) {
      let newState = lodash.cloneDeep(state);
      let { copyStaffName } = action.payload;
      newState.copyStaffName = copyStaffName;
      return newState;
    },
    setCopyStaffId(state, action) {
      let newState = lodash.cloneDeep(state);
      let { copyStaffId } = action.payload;
      newState.copyStaffId = copyStaffId;
      return newState;
    },
    setSettingsIdCopy(state, action) {
      let newState = lodash.cloneDeep(state);
      let { settingsIdCopy } = action.payload;
      newState.settingsIdCopy = settingsIdCopy;
      return newState;
    },
    setSchemeName(state, action) {
      let newState = lodash.cloneDeep(state);
      let { schemeName } = action.payload;
      newState.schemeName = schemeName;
      return newState;
    },
    setPriority(state, action) {
      let newState = lodash.cloneDeep(state);
      let { priority } = action.payload;
      newState.priority = priority;
      return newState;
    },
    setDataDictionaries(state, action) {
      let newState = lodash.cloneDeep(state);
      let { dataDictionaries } = action.payload;
      newState.dataDictionaries = dataDictionaries;
      return newState;
    },
    setDemandFlag(state, action) {
      let newState = lodash.cloneDeep(state);
      let { demandFlag } = action.payload;
      newState.demandFlag = demandFlag;
      return newState;
    },
    setApprovalFlag(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalFlag } = action.payload;
      newState.approvalFlag = approvalFlag;
      return newState;
    },
    setApprovalSelectData(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalSelectData } = action.payload;
      newState.approvalSelectData = approvalSelectData;
      return newState;
    },
    setApprovalData(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalData } = action.payload;
      newState.approvalData = approvalData;
      return newState;
    },
    setApprovalCount(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalCount } = action.payload;
      newState.approvalCount = approvalCount;
      return newState;
    },
    setRecruitmentProjectTable(state, action) {
      let newState = lodash.cloneDeep(state);
      let { recruitmentProjectTable } = action.payload;
      newState.recruitmentProjectTable = recruitmentProjectTable;
      return newState;
    },
    setMyApprovalOrApprovalLog(state, action) {
      let newState = lodash.cloneDeep(state);
      let { myApprovalOrApprovalLog } = action.payload;
      newState.myApprovalOrApprovalLog = myApprovalOrApprovalLog;
      return newState;
    },
    setMyApprovalSubmitPeople(state, action) {
      let newState = lodash.cloneDeep(state);
      let { myApprovalSubmitPeople } = action.payload;
      newState.myApprovalSubmitPeople = myApprovalSubmitPeople;
      return newState;
    },
    setApprovalDetailBanner(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalDetailBanner } = action.payload;
      newState.approvalDetailBanner = approvalDetailBanner;
      return newState;
    },
    setLeaveDetailBanner(state, action) {
      let newState = lodash.cloneDeep(state);
      let { leaveDetailBanner } = action.payload;
      newState.leaveDetailBanner = leaveDetailBanner;
      return newState;
    },
    setEquipmentDetailBanner(state, action) {
      let newState = lodash.cloneDeep(state);
      let { equipmentDetailBanner } = action.payload;
      newState.equipmentDetailBanner = equipmentDetailBanner;
      return newState;
    },
    setApprovalFlowNodeInfo(state, action) {
      let newState = lodash.cloneDeep(state);
      let { approvalFlowNodeInfo } = action.payload;
      newState.approvalFlowNodeInfo = approvalFlowNodeInfo;
      return newState;
    }
  },
  effects: {
    /*
     * 获取交互计划表周数据
     */
    *interactionWeekDataEffects(action, { call, put }) {
      let { year, week, month, weekStartTime, weekFinishTime } = action.payload;
      let params = {};
      if (week) {
        params.year = year;
        params.week = week;
        params.weekStartTime = weekStartTime;
        params.weekFinishTime = weekFinishTime;
      }
      if (month) {
        params.year = year;
        params.month = month;
      }
      let { data } = yield call(request.get, '/planCollection/getPlanList', {
        params
      });
      let result = [];
      let obj = {};
      data.forEach(item => {
        if (obj.hasOwnProperty(item.deliveryManager.toString())) {
          // 该对象存在该键
          obj[item.deliveryManager.toString()].push(item);
        } else {
          // 该对象不存在该键
          obj[item.deliveryManager.toString()] = [];
          obj[item.deliveryManager.toString()].push(item);
        }
      });

      for (let key in obj) {
        obj[key].forEach(item => {
          item.projectCreateTime = moment(item.projectCreateTime).valueOf();
        });
      }

      for (let key in obj) {
        obj[key] = lodash.orderBy(obj[key], ['projectCreateTime'], ['desc']);
      }

      for (let key in obj) {
        obj[key].forEach(item => {
          item.projectCreateTime = moment(item.projectCreateTime).format('YYYY-MM-DD HH:mm:ss');
        });
      }
      data = [];
      for (let key in obj) {
        data.push(...obj[key]);
      }

      data.forEach((item, index) => {
        result.push({
          key: lodash.uniqueId(),
          people: item.staffName,
          projectGroup: item.projectName,
          // planCount: item.plannedQuantity && item.submitState !== 2 ? item.plannedQuantity : null,
          planCount: item.plannedQuantity,
          realFinishCountRecommend: item.recommend,
          realFinishCountSelect: item.filtrate,
          realFinishCountInterview: item.toFace,
          realFinishCountOk: item.interviewPass,
          realFinishCountoffer: item.offerAccept,
          realFinishCountFinish: item.successFul,
          leader: item.leaderName,
          status: item.submitState,
          deliveryManager: item.deliveryManager,
          leaderId: item.leaderId,
          planId: item.planId,
          remarks: item.remarks,
          projectId: item.projectId
        });
      });
      yield put({
        type: 'setPlanTableWeek',
        payload: {
          planTableWeek: result
        }
      });
    },
    /*
     * 提交计划
     */
    *submitPlanEffects(action, { call, put }) {
      let { planId, plannedQuantity, remarks, submitState } = action.payload;
      let { error_code } = yield call(request.post, '/planCollection/updatePlan', {
        data: {
          planId,
          plannedQuantity,
          remarks,
          submitState
        }
      });
      return Promise.resolve(error_code);
    },
    /*
     * 获取提交记录
     */
    // *submitLogEffects(action, { call, put }) {
    //   let { operObject, originId, pageNum, pageSize } = action.payload;
    //   let { data } = yield call(request.get, '/sysLog/page', {
    //     params: {
    //       operObject,
    //       originId,
    //       pageNum,
    //       pageSize
    //     }
    //   });
    //   let result = [];
    //   for (let i = data.records.length - 1; i >= 0; i--) {
    //     result.push(data.records[i]);
    //   }
    //   yield put({
    //     type: 'setSubmitLog',
    //     payload: {
    //       submitLog: result
    //     }
    //   });
    // },
    /*
     * 审批设置表格
     */
    *settingTableDataEffects(action, { call, put, select, take }) {
      let { pageSize, pageNum, visibleStatus, approvalName, approvalEnable, approvalGroupBy } =
        action.payload;

      let { data } = yield call(request.get, '/approval/getSettingsList', {
        params: {
          pageSize,
          pageNum,
          visibleStatus,
          approvalName,
          approvalEnable,
          approvalGroupBy
        }
      });
      yield put({
        type: 'dataDictionariesEffects'
      });
      yield take('dataDictionariesEffects/@@end');
      let dataDictionaries = yield select(({ approval }) => approval.dataDictionaries);

      let result = [];
      data.records.forEach(item => {
        result.push({
          key: lodash.uniqueId(),
          title: item.approvalName,
          group: item.approvalGroupBy,
          updateBy: item.updateBy,
          onOrOff: item.approvalEnable,
          id: item.approvalSettingsId,
          updateTime: item.updateTime,
          approvalDescription: item.approvalDescription,
          approvalExplain: item.approvalExplain,
          approvalProcessKey: item.approvalProcessKey,
          number: item.approvalSort,
          approvalIcon: item.approvalIcon,
          launchStatus: item.launchStatus,
          usableRange: item.usableRange,
          dataDictionaries
        });
      });
      yield put({
        type: 'setSettingsList',
        payload: {
          settingsList: result
        }
      });
      let obj = yield select(({ approval }) => approval.approvalTablePageInfo);
      obj.total = data.total;
      yield put({
        type: 'setApprovalTablePageInfo',
        payload: obj
      });
    },
    /*
     * 更新审批设置表中的数据
     */
    *updateApprovalRowDataEffects(action, { call, put }) {
      let {
        approvalName,
        createBy,
        createTime,
        approvalEnable,
        approvalDescription,
        approvalSettingsId,
        approvalGroupBy,
        approvalExplain,
        approvalSort,
        approvalIcon,
        launchStatus,
        usableRange
      } = action.payload;
      let { error_code } = yield call(request.post, '/approval/settings/update', {
        data: {
          approvalName,
          createBy,
          createTime,
          approvalEnable,
          approvalDescription,
          approvalSettingsId,
          approvalGroupBy,
          approvalExplain,
          approvalSort,
          approvalIcon,
          launchStatus,
          usableRange
        }
      });
      return Promise.resolve(error_code);
    },
    /*
     * 获取可见人表格数据
     */
    *getSeePeopleTableDataEffects(action, { call, put }) {
      let { fid } = action.payload;
      let { data, error_code } = yield call(request.get, '/approval/ApprovalVisibleRangeList', {
        params: {
          fid
        }
      });
      let result = [];
      data.forEach(item => {
        result.push({
          key: lodash.uniqueId(),
          scope:
            item.visiableType == 0
              ? '部门'
              : item.visiableType == 1
                ? '项目'
                : item.visiableType == 2
                  ? '员工'
                  : '--',
          content:
            item.visiableType == 0
              ? item.departmentName
              : item.visiableType == 1
                ? item.projectName
                : item.visiableType == 2
                  ? item.staffName
                  : '--',
          rangeId: item.rangeId
        });
      });
      yield put({
        type: 'setSeePeopelTable',
        payload: {
          seePeopelTable: result
        }
      });
      return Promise.resolve(error_code);
    },
    /*
     * 删除可见人表中的数据行
     */
    *delSeePeopleTableDataEffects(action, { call, put }) {
      let param = new URLSearchParams();
      let { rangeId } = action.payload;
      param.append('rangeId', rangeId);
      let { error_code } = yield call(request.post, '/approval/rangeDeleteByFid', {
        data: param
      });
      return Promise.resolve(error_code);
    },
    /*
     * 可见人添加
     */
    *seePeopleAddEffects(action, { call, put }) {
      let { visiableType, visiableValue, fid } = action.payload;

      let { error_code, error_msg } = yield call(request.post, '/approval/addRange', {
        data: {
          visiableType,
          visiableValue,
          fid
        }
      });
      return Promise.resolve({
        error_code,
        error_msg
      });
    },
    /*
     * 可见人部门数据获取
     */
    *seePeopleDepartmentDataEffects(action, { call, put }) {
      let { data } = yield call(request.get, '/dept/tree');
      yield put({
        type: 'setSeePeopleSelectTree',
        payload: {
          seePeopleSelectTree: data
        }
      });
    },
    /*
     * 操作记录表格数据
     */
    *operationLogTableEffects(action, { call, put }) {
      let { candidateId, operType, operObject } = action.payload;
      let { data } = yield call(request.get, '/talentPool/getRecruitRecordList', {
        params: {
          candidateId,
          operType,
          operObject
        }
      });
      let result = [];
      data.forEach(item => {
        result.push({
          key: lodash.uniqueId(),
          name: item.staffName,
          time: item.createTime,
          operationContent: item.operDescription
        });
      });

      result.forEach(item => {
        item.time = moment(item.time).valueOf();
      });
      result.sort((a, b) => {
        if (a.time < b.time) {
          return 1;
        }
        if (a.time > b.time) {
          return -1;
        }
        return 0;
      });
      result.forEach(item => {
        item.time = moment(item.time).format('YYYY-MM-DD hh:mm:ss');
      });
      yield put({
        type: 'setOperationLogTable',
        payload: {
          operationLogTable: result
        }
      });
    },
    /*
     * 流程设置
     */
    *flowSettingEffects(action, { call, put, select }) {
      let { settingsId } = action.payload;
      let { data, error_code } = yield call(request.get, '/approval/settings/detail', {
        params: {
          settingsId
        }
      });
      let result = [];
      let obj = {};
      data.schemeList[0].flowList.forEach(item => {
        if (!obj.hasOwnProperty(item.grouping.toString())) {
          obj[item.grouping.toString()] = [];
          obj[item.grouping.toString()].push(item);
        } else {
          obj[item.grouping.toString()].push(item);
        }
      });
      for (let key in obj) {
        result.push(obj[key]);
      }

      yield put({
        type: 'setFlowSettingData',
        payload: {
          flowSettingData: result
        }
      });

      yield put({
        type: 'setSchemeId',
        payload: {
          schemeId: data.schemeList[0].schemeId
        }
      });
      yield put({
        type: 'setCopyStaffName',
        payload: {
          copyStaffName: data.copyStaffName.split(',')
        }
      });
      yield put({
        type: 'setCopyStaffId',
        payload: {
          copyStaffId: data.copyStaffId.split(',')
        }
      });
      yield put({
        type: 'setSettingsIdCopy',
        payload: {
          settingsIdCopy: data.schemeList[0].settingsId
        }
      });
      yield put({
        type: 'setSchemeName',
        payload: {
          schemeName: data.schemeList[0].schemeName
        }
      });
      yield put({
        type: 'setPriority',
        payload: {
          priority: data.schemeList[0].priority
        }
      });

      return Promise.resolve(error_code);
    },
    /*
     * 流程更新
     */
    *flowSettingUpdateEffects(action, { call, put }) {
      let { params } = action.payload;
      let { error_code } = yield call(request.post, '/approval/settings/flow/update', {
        data: params
      });
      return Promise.resolve(error_code);
    },
    /*
     * 抄送人
     */
    *copyPeopleEffects(action, { call, put }) {
      let { schemeId, copyStaff, settingsId, schemeName, priority } = action.payload;
      let { error_code } = yield call(request.post, '/approval/addOrUpdateScheme', {
        data: {
          schemeId,
          copyStaff,
          settingsId,
          schemeName,
          priority
        }
      });
      return Promise.resolve(error_code);
    },
    /*
     * 数据字典
     */
    *dataDictionariesEffects(action, { call, put }) {
      let { data, error_code } = yield call(getDataDictionary, 'WORKFLOW_GROUP');
      let result = data.map(item => {
        return {
          label: item.dictValueDesc,
          value: item.dictValue
        };
      });
      yield put({
        type: 'setDataDictionaries',
        payload: {
          dataDictionaries: result
        }
      });
      return Promise.resolve({
        code: error_code,
        data: result
      });
    },
    /*
     * 通过key获取数据字典
     */
    *dataDictionariesByKeyEffects(action, { call, put }) {
      let { key } = action.payload;
      let { data, error_code } = yield call(getDataDictionary, key);
      // let result = data.map((item) => {
      //   return {
      //     label: item.dictValueDesc,
      //     value: item.dictValue
      //   }
      // });
      return Promise.resolve({
        code: error_code,
        data
      });
    },
    /*
     * 获取审批记录已结束表格数据
     */
    *approvalLogOverEffects(action, { call, put }) {
      let { pageNum, pageSize, dictNameWorkFloGroup } = action.payload;
      let res = yield [
        call(request.get, '/sysDictionary/getDictByName', {
          params: {
            dictName: dictNameWorkFloGroup
          }
        }),
        call(request.get, '/approval/selectClosingApprovalRecordList', {
          params: {
            pageNum,
            pageSize
          }
        })
      ];
      let dicResult = res[0].data.map(item => {
        return {
          label: item.dictValueDesc,
          value: item.dictValue
        };
      });
      let result = res[1].data.records.map((item, index) => {
        return {
          ...item,
          key: index,
          procSubmitStaffName: `${item.departmentName ? item.departmentName + '-' : ''}${item.procSubmitStaffName
            }`,
          dicResult
        };
      });
      yield put({
        type: 'setApprovalData',
        payload: {
          approvalData: result
        }
      });
      yield put({
        type: 'setApprovalCount',
        payload: {
          approvalCount: res[1].data.total
        }
      });
      return Promise.resolve({
        code: 200
      });
    },
    /*
     * 获取审批记录待审批表格数据
     */
    *approvalLogWaitEffects(action, { call, put }) {
      let { pageNum, pageSize, dictNameWorkFloGroup, dictNameTimeout } = action.payload;
      let res = yield [
        call(request.get, '/sysDictionary/getDictByName', {
          params: {
            dictName: dictNameWorkFloGroup
          }
        }),
        call(request.get, '/approval/selectRecordToBeApprovedList', {
          params: {
            pageNum,
            pageSize
          }
        }),
        call(request.get, '/sysDictionary/getDictByName', {
          params: {
            dictName: dictNameTimeout
          }
        })
      ];
      let dicResult = res[0].data.map(item => {
        return {
          label: item.dictValueDesc,
          value: item.dictValue
        };
      });
      let result = res[1].data.records.map((item, index) => {
        return {
          ...item,
          key: index,
          procSubmitStaffName: item.procSubmitStaffName,
          status: '待审批',
          dicResult,
          timeout: parseFloat(res[2].data[0].dictValueDesc)
        };
      });
      yield put({
        type: 'setApprovalData',
        payload: {
          approvalData: result
        }
      });
      yield put({
        type: 'setApprovalCount',
        payload: {
          approvalCount: res[1].data.total
        }
      });
      return Promise.resolve({
        code: 200
      });
    },
    /*
     * 获取不同状态的审批数据
     */
    *getApprovalStatusDataEffects(action, { call, put }) {
      let {
        assignee,
        pageNum,
        pageSize,
        procName,
        procSubmitStaffName,
        procType,
        startDate,
        endDate,
        procStatus,
        procSubmitStaffId,
        receiveId,
        groupBy,
        type,
        dictNameWorkFloGroup,
        perms
      } = action.payload;
      let res = yield [
        call(request.get, '/sysDictionary/getDictByName', {
          params: {
            dictName: dictNameWorkFloGroup
          },
        }),
        call(request.get, '/approval/getTaskListByAssignee', {
          params: {
            assignee,
            pageNum,
            pageSize,
            procName,
            procSubmitStaffName,
            procType,
            startDate,
            endDate,
            procStatus,
            procSubmitStaffId,
            receiveId,
            groupBy,
            type
          },
          headers: {
            perms
          }
        })
      ];
      let dicResult = res[0].data.map(item => {
        return {
          label: item.dictValueDesc,
          value: item.dictValue
        };
      });
      let result = [];
      res[1].data.records.forEach(item => {
        result.push({
          dicResult,
          key: lodash.uniqueId(),
          procInstId: item.procInstId,
          procName: item.procName,
          procSubmitStaffName: item.procSubmitStaffName,
          processStartTime: item.processStartTime,
          group: item.groupBy,
          procType: item.procType,
          procSubmitStaffId: item.procSubmitStaffId,
          stauts: item.stauts,
          procStatus: item.procStatus,
          departmentName: item.departmentName,
          approvalName: item.approvalName,
          procInstId: item.procInstId,
          definitionKey: item.definitionKey,
          procDetailId: item.procDetailId,
          taskId: item.taskId,
          adoptType: item.adoptType,
          isForbidden: item.isForbidden,
          isTimeOut: item.isTimeOut,
          staffName: item.staffName,
          processingTimes: item.processingTimes,
          currentHandlerPerson: item.currentHandlerPerson,
          procBaseId: item.procBaseId,
          phoneNum: item.phoneNum
        });
      });

      yield put({
        type: 'setApprovalData',
        payload: {
          approvalData: result
        }
      });

      yield put({
        type: 'setApprovalCount',
        payload: {
          approvalCount: res[1].data.total
        }
      });

      return Promise.resolve(res[1].error_code);
    },
    /*
     * 获取所有的假期
     */
    *getAllVacationEffects(action, { call, put }) {
      let res = yield call(request.get, '/holiday/getVisibilityHolidayList');
      let result = res.data.filter(item => item.hid < 12 || item.hid > 14);

      yield put({
        type: 'setVacationList',
        payload: {
          vacationList: result
        }
      });
    },
    /*
     * 审批详情
     */
    *getAppprovalInfoEffects(action, { call, put, select, take }) {
      let { staffId, procInstId, key } = action.payload;
      let { data: dataDetail } = yield call(request.get, '/specialist/getHolidayOpenInfo', {
        params: {
          staffId,
          procInstId
        }
      });
      yield put({
        type: 'setApplyAttachment',
        payload: {
          applyAttachment: dataDetail[0].applyAttachment ? dataDetail[0].applyAttachment : []
        }
      });
      yield put({
        type: 'getAllVacationEffects'
      });
      yield take('getAllVacationEffects/@@end');
      let vacationList = yield select(({ approval }) => {
        return approval.vacationList;
      });

      let holidayType = vacationList.find(item => {
        return item.hid == dataDetail[0].holidayType;
      });

      let startTime;
      let endTime;
      let sumTime = 0;
      if (dataDetail.length == 1) {
        startTime = `${dataDetail[0].holidayDate} ${dataDetail[0].holidayStartTime ? dataDetail[0].holidayStartTime : ''
          }`;
        endTime = `${dataDetail[0].holidayDate} ${dataDetail[0].holidayEndTime ? dataDetail[0].holidayEndTime : ''
          }`;
      } else {
        startTime = `${dataDetail[0].holidayDate} ${dataDetail[0].holidayStartTime ? dataDetail[0].holidayStartTime : ''
          }`;
        endTime = `${dataDetail[dataDetail.length - 1].holidayDate} ${dataDetail[dataDetail.length - 1].holidayEndTime
          ? dataDetail[dataDetail.length - 1].holidayEndTime
          : ''
          }`;
      }
      if (holidayType.applyUnit == 0) {
        dataDetail.forEach(item => {
          sumTime += parseFloat(item.holidayHours);
        });
      } else {
        sumTime = dataDetail[0].days;
      }
      let submitPeople = yield select(({ approval }) => approval.currentRowApproval);
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let { data: attendanceDtail } = yield call(
        request.get,
        '/attendance/getStaffAttendancePlan',
        {
          params: {
            staffId: submitPeople.procSubmitStaffId ? submitPeople.procSubmitStaffId : staffId
          }
        }
      );

      let attendanceTime = countCurrentDayTime(attendanceDtail);
      let taskDefKeys;
      let timeResult;
      if (holidayType.applyUnit == 0) {
        // 小时
        timeResult = sumTime / attendanceTime;
      } else {
        // 天
        timeResult = sumTime;
      }
      if (timeResult < 3) {
        taskDefKeys = 'user2,user3';
      } else if (timeResult >= 3 && timeResult < 5) {
        taskDefKeys = 'user3';
      } else {
        taskDefKeys = null;
      }

      let newProcess = yield call(request.get, '/approval/getApprovalProcess', {
        params: {
          procId: procInstId
        }
      });

      let processNode = [];
      newProcess.data[0].forEach(item => {
        if (item.flowCategory == 1) {
          processNode.push({
            title: '或签',
            flowCategory: item.flowCategory,
            name: item.flowStaffName + (item.userStatus ? item.userStatus : ''),
            message: item.opinion,
            information: item.information,
            time: item.updateTime,
            headUrl: item.sysUserHead,
            status: item.status
          });
        } else if (item.flowCategory == 2) {
          processNode.push({
            title: '会签',
            flowCategory: item.flowCategory,
            name: item.flowStaffName + (item.userStatus ? item.userStatus : ''),
            message: item.opinion,
            information: item.information,
            time: item.updateTime,
            headUrl: item.sysUserHead,
            status: item.status
          });
        } else {
          processNode.push({
            title: '普通签',
            flowCategory: item.flowCategory,
            message: item.opinion,
            information: item.information,
            time: item.updateTime,
            name: item.flowStaffName + (item.userStatus ? item.userStatus : ''),
            headUrl: item.sysUserHead,
            status: item.status
          });
        }
      });

      let obj = yield select(({ approval }) => approval.currentRowApproval);
      if (!obj.processStartTime) {
        obj.processStartTime = processNode[0].time;
        yield put({
          type: 'setCurrentRowApproval',
          payload: {
            currentRowApproval: obj
          }
        });
      }

      yield put({
        type: 'setApprovalDetailBanner',
        payload: {
          approvalDetailBanner: {
            mainInfo: {
              type: holidayType.holidayType,
              applyUnit: holidayType.applyUnit,
              startTime,
              endTime,
              sumTime,
              reason: dataDetail[0].reason
            },
            process: processNode,
            baseInfo: {
              name: obj.procSubmitStaffName,
              type: obj.approvalName,
              number: obj.procInstId,
              project: obj.departmentName,
              time: obj.processStartTime,
              msg: obj.procName,
              headUrl: newProcess.data[0][0].sysUserHead,
              phoneNum: newProcess.data[0][0].phoneNum
            },
            recipients: newProcess.data[1]
          }
        }
      });
    },
    /*
     * 离职审批详情
     */
    *getLeavaDetailEffects(action, { call, put }) {
      let { procDetailId, processKey, procId } = action.payload;
      let { data: dataDetail } = yield call(request.get, '/approval/getLeaveProcessDetail', {
        params: {
          procDetailId,
          processKey
        }
      });
      yield put({
        type: 'setLeavaDetailEffects',
        payload: {
          leavaDetail: dataDetail ? dataDetail : []
        }
      });
      yield put({
        type: 'setApplyAttachment',
        payload: {
          applyAttachment: dataDetail.sysFileList ? dataDetail.sysFileList : []
        }
      });
      let newProcess = yield call(request.get, '/approval/getApprovalProcess', {
        params: {
          procId
        }
      });
      yield put({
        type: 'setLeaveDetailBanner',
        payload: {
          leaveDetailBanner: {
            process: newProcess.data[0],
            recipients: newProcess.data[1]
          }
        }
      });
    },
    /*
     * 设备申领审批详情
     */
    *getEquipmentDetailEffects(action, { call, put }) {
      let { procBaseId, key, procId } = action.payload;
      let { data: dataDetail } = yield call(request.get, '/assets/getEquipmentProcess', {
        params: {
          procBaseId,
          key
        }
      });
      yield put({
        type: 'setEquipmentDetailEffects',
        payload: {
          equipmentDetail: dataDetail ? dataDetail : []
        }
      });
      yield put({
        type: 'setApplyAttachment',
        payload: {
          applyAttachment: dataDetail.sysFiles ? dataDetail.sysFiles : []
        }
      });
      let newProcess = yield call(request.get, '/approval/getApprovalProcess', {
        params: {
          procId
        }
      });
      yield put({
        type: 'setEquipmentDetailBanner',
        payload: {
          equipmentDetailBanner: {
            process: newProcess.data[0],
            recipients: newProcess.data[1]
          }
        }
      });
    },
    /*
     * 开具证明批详情
     */
    *getProveThatOpens(action, { call, put }) {
      let { procDetailId, procType, procId } = action.payload;
      let { data: dataDetail } = yield call(request.get, '/approval/proveProcessDetail', {
        params: {
          procType,
          procDetailId
        }
      });

      yield put({
        type: 'setProveThatOpensDetail',
        payload: {
          proveThatOpensDetail: dataDetail ? dataDetail : []
        }
      });
      yield put({
        type: 'setApplyAttachment',
        payload: {
          applyAttachment: dataDetail.sysFiles ? dataDetail.sysFiles : []
        }
      });
      let newProcess = yield call(request.get, '/approval/getApprovalProcess', {
        params: {
          procId
        }
      });
      yield put({
        type: 'setEquipmentDetailBanner',
        payload: {
          equipmentDetailBanner: {
            process: newProcess.data[0],
            recipients: newProcess.data[1]
          }
        }
      });
    },
    /*
     * 获取提交人
     */
    *getSubmitPeopleEffects(action, { call, put }) {
      let { staffId } = action.payload;
      let { data } = yield call(request.get, '/approval/getSubmittedPeople', {
        params: {
          staffId
        }
      });
      let result = [];
      data.forEach(item => {
        result.push({
          label: item.procSubmitStaffName,
          value: item.procSubmitStaffId
        });
      });
      yield put({
        type: 'setMyApprovalSubmitPeople',
        payload: {
          myApprovalSubmitPeople: result
        }
      });
    },
    /*
     * 获取审批记录中的操作记录
     */
    *getApprovalInOperationLogEffects(action, { call, put }) {
      let { pageNum, pageSize } = action.payload;

      let { data } = yield call(request.get, '/approval/getTransmitRecord', {
        params: {
          pageNum,
          pageSize
        }
      });
      let result = [];
      data.records.forEach(item => {
        result.push({
          key: lodash.uniqueId(),
          operationTime: moment(item.commentTime).format('YYYY-MM-DD HH:mm:ss'),
          operationPeople: item.commentUserName,
          operationType: item.type == 'transmit' ? '转交' : '--',
          operationLog: item.comment,
          procName: item.procName
        });
      });
      yield put({
        type: 'setApprovalData',
        payload: {
          approvalData: result
        }
      });
      yield put({
        type: 'setApprovalCount',
        payload: {
          approvalCount: data.total
        }
      });
    },
    /*
     * 审批提交
     */
    *approvalSubmitEffects(action, { call, put }) {
      let { holidayId, taskId, procInstId, endFlag, procStatus, reason } = action.payload;
      let { error_code } = yield call(request.post, '/approval/LeaveCompleteTask', {
        data: {
          holidayId,
          taskId,
          procInstId,
          endFlag,
          procStatus,
          reason
        }
      });
      return Promise.resolve(error_code);
    },
    /*
     * 转交
     */
    *deliverEffects(action, { call, put }) {
      let { userId, taskId, processInstanceId, message, staffName, assignee } = action.payload;
      let { error_code } = yield call(request.post, '/approval/delegation', {
        data: [
          {
            userId,
            taskId,
            processInstanceId,
            message,
            staffName,
            assignee
          }
        ]
      });
      return Promise.resolve(error_code);
    },
    /*
     * 批量修改审批配置
     */
    *updateAllApprovalEffects(action, { call, put }) {
      let { params } = action.payload;
      let res = yield call(request.post, '/approval/settings/flow/flowUpdate', {
        data: params
      });
      return Promise.resolve(res['error_code']);
    },
    /*
     * 转交
     */
    *forwardingPeopleEffects(action, { call, put }) {
      let { userId, taskId, processInstanceId, message, staffName, assignee } = action.payload;
      let res = yield call(request.post, '/approval/delegation', {
        data: [
          {
            userId,
            taskId,
            processInstanceId,
            message,
            staffName,
            assignee
          }
        ]
      });
      return Promise.resolve(res['error_code']);
    },
    /*
     * 人事花名册导入记录
     */
    *importRecord(action, { call, put }) {
      let { pageNum, pageSize, type, startTime, endTime, operation, operObject } = action.payload;
      let res = yield call(request.post, '/sysImport/getImportUserExcelLog', {
        data: {
          pageNum,
          pageSize,
          type,
          startTime,
          endTime,
          operation,
          operObject
        }
      });
      let result = [];
      res.data.records.forEach(item => {
        result.push({
          key: lodash.uniqueId(),
          name: item.staffName,
          importType: item.operTypeDesc,
          count: item.operDescription,
          importTime: item.operTimestamp
        });
      });

      return Promise.resolve({
        code: res['error_code'],
        total: res.data.total,
        result
      });
    },
    /*
     * 审批图标上传
     */
    *approvalIconUploadEffects(action, { call, put }) {
      let { frmData } = action.payload;
      let res = yield call(request.post, '/sysFile/upload', {
        data: frmData
      });

      yield put({
        type: 'setApprovalBasicInfoIcon',
        payload: {
          approvalBasicInfoIcon: res.data[0]
        }
      });
      return Promise.resolve({
        code: res['error_code'],
        data: res.data[0]
      });
    },
    /*
     * 获取剩余调休
     */
    *getResidueHolidayEffects(action, { call, put, take }) {
      let { staffId } = action.payload;
      let res = yield call(request.get, '/holiday/getOvertimeBalance', {
        params: {
          staffId
        }
      });
      return Promise.resolve({
        data: res.data
      });
    },
    /*
     * 获取项目组信息
     */
    *getAllProjectInfoEffects(action, { call, put }) {
      let res = yield call(request.get, '/attendance/getProjectTree');
      yield put({
        type: 'setProjectInfo',
        payload: {
          projectInfo: res.data
        }
      });
    },
    /*
     * 项目人员考勤信息
     */
    *projectEmployeeTimeEffects(action, { call, put }) {
      let { projectId } = action.payload;
      let res = yield call(request.get, '/attendance/getCustomerContract', {
        params: {
          projectId
        }
      });

      return Promise.resolve({
        time: parseFloat(res.data.computeHours)
      });
    },
    /*
     * 获取流程记录
     */
    *getFlowRecordEffects(action, { call, put }) {
      let { procId } = action.payload;
      let res = yield call(request.get, '/approval/getApprovalProcess', {
        params: {
          procId
        }
      });

      return Promise.resolve({
        code: res.error_code,
        flowNode: res.data[0],
        copyPeople: res.data[1]
      });
    },
    /*
     * 更新流程记录
     */
    *updateFlowRecordEffects(action, { call, put }) {
      let { approvalProcessList } = action.payload;
      let res = yield call(request.post, '/approval/updateProcessStatus', {
        data: approvalProcessList
      });
    },
    /*
     * 获取数据字典数据
     */
    *getDataDictionaryEffects(action, { call, put }) {
      let res = yield call(request.get, '/sysDictionary/getDictByName', {
        params: action.payload
      });
      let result = res.data.map(item => {
        return {
          label: item.dictValueDesc,
          value: item.dictValue
        };
      });
      return Promise.resolve({
        code: res['error_code'],
        data: result
      });
    },
    /*
     * 获取考勤时间
     */
    *attendanceTimeEffects(action, { call, put }) {
      let res = yield call(request.get, '/attendance/summary/date');
      let result = res.data ? res.data : moment().format('YYYYMM');
      let time = moment(result, 'YYYYMM').startOf('month').format('YYYY-MM-DD');
      yield put.resolve({
        type: 'setAttendanceTimes',
        payload: {
          attendanceTimes: time
        }
      });
      return Promise.resolve(res.error_code);
    },
    /*
     * 获取所有考勤分段数据
     */
    *getAttendanceDataEffects(action, { call, put }) {
      let { staffId, month, year } = action.payload;
      let res = yield call(request.get, '/attendance/getProjectInformation', {
        params: {
          staffId,
          month,
          year
        }
      });
      yield put.resolve({
        type: 'setAttendanceData',
        payload: {
          attendanceData: res.data.records
        }
      });
      return Promise.resolve(res.error_code);
    },
    /*
     * 获取银行卡列表数据
     */
    *bankListEffects(action, { call, put }) {
      let { activated } = action.payload;
      let res = yield call(request.get, '/personnelConfig/mainCompanyConfigs/1', {
        params: {
          activated
        }
      });
      yield put.resolve({
        type: 'setBankList',
        payload: {
          bankList: res.data
        }
      });
      return Promise.resolve(res.error_code);
    }
  }
};
