import { query as queryUsers, queryCurrent } from '@/services/user'

export default {
  namespace: 'user',

  state: {
    list: [],
    buttonsAuthList: (() => {
      let buttonsAuthList = localStorage.getItem('buttonsAuthList');
      let isBut = {};
      if (buttonsAuthList && buttonsAuthList != 'undefined') {
        isBut = JSON.parse(buttonsAuthList);
      }
      return isBut;
    })(),
    currentUser: !window.localStorage.getItem('userInfo') ? {} : JSON.parse(window.localStorage.getItem('userInfo'))
  },

  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers)
      yield put({
        type: 'save',
        payload: response
      })
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload
      }
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      }
    },
    saveButtonsAuthList(state, action) {
      if (action && action.payload && Object.keys(action.payload).length) {
        window.localStorage.setItem('buttonsAuthList', JSON.stringify(action.payload))
      } else {
        window.localStorage.removeItem('buttonsAuthList')
      }

      return {
        ...state,
        buttonsAuthList: action.payload || {},
      }
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount
        }
      }
    }
  }
}
