import {
  queryProjectNotice
} from '@/services/api';
import lodash from 'lodash';

export default {
  namespace: 'project',
  state: {
    notice: [],
    // 控制需求表中的需求重启Drawer
    restartFlags: false
  },
  reducers: {
    saveNotice(state, action) {
      return {
        ...state,
        notice: action.payload,
      };
    },
    setRestartFlags(state, action) {
      // debugger;      
      let newState = lodash.cloneDeep(state);
      let {
        restartFlags
      } = action.payload;
      newState.restartFlags = restartFlags;
      return newState;
    }
  },
  effects: {
    * fetchNotice(_, {
      call,
      put
    }) {
      const response = yield call(queryProjectNotice);
      yield put({
        type: 'saveNotice',
        payload: Array.isArray(response) ? response : [],
      });
    },
  }
};
