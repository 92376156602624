import moment from 'moment';
import React from 'react';
// import nzh from 'nzh/cn';
import { parse, stringify } from 'qs';
import request from '@/utils/request';
import constants from './constants';
import PublicUtils from "@/utils/publicUtils";

export function getConst(key) {
  if (Object.keys(constants).includes(key)) {
    return constants[key];
  }
  return '';
}

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

// tab权限
export function authorityControl(list, type) {
  if (type) {
    let tabList = list.find(item => item.type);
    return tabList && tabList.key
  } else {
    let tabList = [];
    list.map(item => {
      if (item.type) {
        tabList.push({
          key: item.key,
          tab: item.tab,
        })
        return
      }
    })
    return tabList
  }
}

// 数据字典值
export function getDataDictionary(dictName, perms) {
  return request.get('/sysDictionary/getDictByName', { params: { dictName }, headers: { perms } });
}
/**
 * @description 数据字典英文值与中文值之间的转换
 * @param {String} value 需要转换的字符串
 * @returns {String} 转换后的字符串
 */
export function dicValTransform(value) {
  const arr = localStorage.getItem('dictionary') ? PublicUtils.getDictionary('JOB_APPLY_CITY') : '';
  if (!arr) return
  let current = '';
  if (/[\u4e00-\u9fa5]/.test(value)) {
    // 匹配中文
    current = arr.find((item) => item.dictValueDesc == value);
    if (current) {
      return current.dictValue;
    }
  } else if (/[a-zA-Z]+/gi.test(value)) {
    // 匹配英文/[a-zA-Z]/
    current = arr.find((item) => item.dictValue == value);
    if (current) {
      return current.dictValueDesc;
    }
  }
  return current;
}

// 计划人数
export function getPlanNumber(val) {
  /**
   * val 计划完成数
   * offer 计划Offer
   * pass 计划通过
   * interview 计划到面
   * select 计划筛选
   * recommend 计划推荐
   */
  if (typeof val !== 'number') {
    return [0, 0, 0, 0, 0, 0];
  }
  return [
    Number(val),
    Math.round((5 * val) / 4),
    Math.round((25 * val) / 14),
    Math.round((25 * val) / 7),
    Math.round((250 * val) / 49),
    Math.round((500 * val) / 49)
  ];
}

export function isCanUpLoad(file, allowType, allowSize, allowImage) {
  /**
   * file 文件
   * allowType 允许上传文件类型,类型:数组 例:[doc,xls]
   * allowSize 允许上传文件大小,类型:数字 单位: Mb
   * allowImage 允许上传文件为图片类型，类型：布尔值
   */
  let isCanUpLoad = false;
  // 设置为不可上传状态
  if (file) {
    // 是否存在文件
    let isAllowType = true;
    let isAllowSize = true;
    // 预设文件类型与文件大小为可上传状态
    if (allowType.length) {
      // 判断是否有设置文件上传类型限制
      if (!file.name) {
        // 判断是否能获取文件类型
        isAllowType = false;
      } else {
        const fileNameArr = file.name.split('.');
        const fileType = fileNameArr[fileNameArr.length - 1].toLowerCase();
        isAllowType = allowType.some((item) => fileType.includes(item));
        // 截取文件类型 判断是否符合条件
      }
      if (allowImage && file.type) {
        // 判断限制上传文件类型是否为全部文件格式
        if (file.type.includes('image')) {
          // 判断上传文件类型是否为图片格式
          isAllowType = true;
        }
      }
    }
    if (allowSize || allowSize === 0) {
      // 判断是否有限制文件上传大小
      if (!file.size) {
        // 判断是否能获取文件大小
        isAllowSize = false;
      } else {
        // 判断上传文件大小是否符合限制条件
        isAllowSize = file.size / 1024 / 1024 < allowSize;
      }
    }
    // 符合条件，设置文件为可上传状态
    isAllowType && isAllowSize && (isCanUpLoad = true);
  }
  return isCanUpLoad;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000)
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

// export function digitUppercase(n) {
//   return nzh.toMoney(n);
// }

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter((item) => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every((item) => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    (routePath) => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some((route) => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export function isAntdPro() {
  return window.location.hostname === 'preview.pro.ant.design';
}

export const importCDN = (url, name) =>
  new Promise((resolve) => {
    const dom = document.createElement('script');
    dom.src = url;
    dom.type = 'text/javascript';
    dom.onload = () => {
      resolve(window[name]);
    };
    document.head.appendChild(dom);
  });

// 根据生日计算年龄
export function birthday2age(birthday) {
  if (!birthday) return '';

  const birthDate = new Date(birthday);
  const today = new Date();
  // 时间差以毫秒计算  
  let ageDiff = today - birthDate;
  // 将毫秒转换为年  
  ageDiff /= (1000 * 60 * 60 * 24 * 365.25);
  // 向下取整得到完整年数，即为年龄
  const age = Math.floor(ageDiff);

  return age;
}


// 手动列表排序
export function listSort(type, formValues) {
  if (!formValues.sortType) return false
  let sortType = false;
  if (formValues.sortType == type) {
    sortType = formValues.sort == 'desc' ? 'descend' : 'ascend';
  }
  return sortType;
}