import lodash from 'lodash';
import request from '@/utils/request.js';
import { saveFlowNode } from '@/services/approvalFlow.js';
import { notification } from 'antd'
/**
 * @description 递归处理树数据
 * @param {Array} v 需要处理的树数据
 * @return {Array} 递归处理后的数据
 */
function processTree(v) {
  let res = v.map(item => {
    if (item.children) {
      return {
        title: item.departmentName,
        key: item.departmentId,
        type: item.departmentFlag,
        projectId: item.projectId,
        pid: item.pid,
        children: [...processTree(item.children)]
      };
    }
    return {
      title: item.departmentName,
      key: item.departmentId,
      type: item.departmentFlag,
      projectId: item.projectId
    };
  });
  return res;
}

export default {
  namespace: 'dataInfo',
  state: {
    // 审批配置信息
    asInfo: {}
  },
  reducers: {
    asInfoReducers(state, action) {
      let newState = lodash.cloneDeep(state);
      let { asInfo } = action.payload;
      newState.asInfo = asInfo;
      return newState;
    }
  },
  effects: {
    /*
     * 获取审批简介和填表说明
     */
    *approvalSettingInfoEffects(action, { call, put }) {
      let { approvalSettingsId } = action.payload;
      let res = yield call(request.get, '/approval/getSettingsDetail', {
        params: {
          approvalSettingsId
        }
      });
      yield put.resolve({
        type: 'asInfoReducers',
        payload: {
          asInfo: res.data
        }
      });
    },
    /*
     * 查询本部招聘需求已关联候选人
     */
    *headquartersRecruitmentListEffects(action, { call, put }) {
      let res = yield call(request.get, '/recruitmentProgress/getAssociatedCandidateList', {
        params: action.payload
      });

      let arr = res.data.records.map(item => ({
        key: lodash.uniqueId(),
        name: item.candidateName,
        time: item.pushDate,
        updateTime: item.operationTime,
        status: item.pushPhase
      }));
      return Promise.resolve({
        data: arr,
        total: res.data.total
      });
    },
    /*
     * 获取本部人员招聘审批流程节点
     */
    *headquartersRecruitmentNodeEffects(action, { call, put }) {
      let { key, obj, userId, procSubmitStaffId } = action.payload;
      let res = yield call(request.post, '/approval/getRestPath', {
        data: {
          key,
          userId,
          procSubmitStaffId,
          obj
        }
      });
      res.data = res.data
        ? res.data
        : {
          approvalDate: [],
          copeStaff: []
        };
      return Promise.resolve({
        nodeData: res.data.approvalDate,
        peopleData: res.data.copeStaff,
        code: res.error_code,
        msg: res.error_msg
      });
    },
    /*
     * 获取本部人员招聘审批基本信息
     */
    *getBasicInfoRecruitmentEffects(action, { call, put }) {
      let { procDetailId, procType } = action.payload;
      let res = yield call(request.get, '/approval/getRecruitmentDemandProcessInfo', {
        params: {
          procDetailId,
          procType
        }
      });
      let obj = {
        recruitmentDemandId: res.data.recruitmentDemandId,
        orderByName: res.data.orderByName,
        dutyName: res.data.dutyName,
        dutyStaffEstablish: res.data.dutyStaffEstablish,
        levelStaffEstablish: res.data.levelStaffEstablish,
        staffJobNum: res.data.staffJobNum,
        jobLevelNum: res.data.jobLevelNum,
        maxSalary: res.data.maxSalary,
        minSalary: res.data.minSalary,
        dictValue: res.data.dictValue,
        // 人员编制、在职人数、薪资范围
        levelName: res.data.levelName,
        // 人员编制、在职人数
        deptName: res.data.deptName,
        leaderName: res.data.leaderName,
        recruitNumber: res.data.recruitNumber,
        // 招聘原因
        note: res.data.note,
        interviewerName: res.data.interviewerName,
        workArea: res.data.workArea,
        workLocale: res.data.workLocale,
        jobResponsibilitie: res.data.jobResponsibilitie,
        qualification: res.data.qualification
      };
      return Promise.resolve(obj);
    },
    /*
     * 完成本部人员招聘需求审批
     */
    *finishRecruitmentEffects(action, { call, put }) {
      let { recruitmentDemandId, procBaseId, procId, procStatus, comment } = action.payload;
      let res = yield call(request.post, '/approval/completeRecruitmentDemand', {
        data: {
          recruitmentDemandId,
          procBaseId,
          procId,
          procStatus,
          comment
        }
      });
      return Promise.resolve(res.error_code);
    },
    /*
     * 获取地区下所有的工作地点
     */
    *getAllLocalEffects(action, { call, put }) {
      let { area } = action.payload;
      let res = yield call(request.get, '/staffArea/getWorkplace', {
        params: {
          area
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 发起本部offer审批时的详情
     */
    *getHeadquartersOfferLaunchDetailEffects(action, { call, put }) {
      let { followId } = action.payload;
      let res = yield call(request.get, '/approval/getHeadquartersOfferLaunchDetail', {
        params: {
          followId
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 查询本部招聘需求详情
     */
    *getRecruitmentDemandDetailEffects(action, { call, put }) {
      let { recDemandId } = action.payload;
      let res = yield call(request.get, '/recruitmentProgress/getRecruitmentDemandDetail', {
        params: {
          recDemandId
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 获取社保缴纳方式
     */
    *getSocinsuListEffects(action, { call, put }) {
      let res = yield call(request.get, '/personnelConfig/paymentMethods');
      return Promise.resolve(res.data);
    },
    /**
     * 获取社保缴纳机构
     */
    *getSocialAgencyConfigsEffects(action, { call, put }) {
      let res = yield call(request.get, '/personnelConfig/socialAgencyConfigs');
      return Promise.resolve(res.data);
    },
    /**
     * 获取银行卡信息、主体公司信息
     */
    *getBankCompanyEffects(action, { call, put }) {
      let { type, activated } = action.payload;
      let res = yield call(request.get, `/personnelConfig/mainCompanyConfigs/${type}`, {
        params: {
          activated
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 获取候选人详情
     */
    *getWaitPeopleDetailEffects(action, { call, put }) {
      let { candidateId } = action.payload;
      let res = yield call(request.get, '/talentPool/getTalentDetailById', {
        params: {
          candidateId
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 发起本部offer审批
     */
    *startHeadquartersOfferEffects(action, { call, put }) {
      let res = yield call(request.post, '/approval/startHeadquartersOfferProcess', {
        data: action.payload
      });
      return Promise.resolve({
        error_code: res.error_code,
        error_msg: res.error_msg
      });
    },
    /**
     * 获取本部offer审批详情
     */
    *getHeadquartersOfferBasicInfoEffects(action, { call, put }) {
      let { procDetailId } = action.payload;
      let res = yield call(request.get, '/approval/getHeadquartersOfferProcessDetail', {
        params: {
          procDetailId
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 完成本部offer审批
     */
    *finishHeadquartersOfferEffects(action, { call, put }) {
      let res = yield call(request.post, '/approval/completeHeadquartersOfferProcess', {
        data: action.payload
      });
      return Promise.resolve(res);
    },
    /**
     * 获取本部offer发起流程节点前置条件（职务）
     */
    *getDutyExceedInOffer(action, { call, put }) {
      let { dutyId, levelId } = action.payload;
      let res = yield call(request.get, '/approval/getDutyExceedInOffer', {
        params: {
          dutyId
        }
      });
      return Promise.resolve(res);
    },
    /**
     * 获取本部offer发起流程节点前置条件（职级）
     */
    *getLevelExceedInOffer(action, { call, put }) {
      let { levelId } = action.payload;
      let res = yield call(request.get, '/approval/getLevelExceedInOffer', {
        params: {
          levelId
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 获取候选人其它offer审批
     */
    *getSurplusOfferEffects(action, { call, put }) {
      let res = yield call(request.get, '/approval/currentOffer', {
        params: action.payload
      });
      let list = res.data.map(item => ({
        key: lodash.uniqueId(),
        name: item.procName,
        jobDepart: item.projectName
          ? item.projectName
          : item.departmentName
            ? item.departmentName
            : '--',
        skillDuty: item.duty ? item.duty : item.dutyName ? item.dutyName : '--',
        level: item.level ? item.level : item.levelName ? item.levelName : '--',
        contract: `${item.contractPeriod}年`,
        employ: `${item.probation}个月`,
        startTime: item.createTime,
        remark: item.remark,
        followId: item.followId,
        procDetailId: item.procOfferId,
        procInstId: item.procInstId,
        procSubmitStaffName: item.procSubmitStaffName,
        phoneNum: item.phoneNum,
        procStatus: item.procStatus,
        processStartTime: item.processStartTime,
        projectName: item.projectName,
        departmentName: item.departmentName,
        procType: item.procType,
        offerProcessType: item.offerProcessType
      }));
      return Promise.resolve(list);
    },
    /**
     * 获取客户数据
     */
    *clientSelectDataEffects(action, { call, put }) {
      let res = yield call(request.get, '/cusrCot/getContractListForCalc', {
        headers: {
          perms: '/dashboard'
        }
      });
      return Promise.resolve(res.data);
    },
    /**
     * 获取地区数据
     */
    *areaSelectDataEffects(action, { call, put }) {
      let res = yield call(request.get, '/cusrCot/getRateList', {
        params: action.payload
      });
      return Promise.resolve(res.data.records);
    },
    /**
     * 达标测算
     */
    *standardCalculateEffects(action, { call, put }) {
      let res = yield call(request.get, '/grossProfit/getWageCountGrossProfit', {
        params: action.payload
      });
      return Promise.resolve(res.data);
    },
    /**
     * 项目offer发起
     */
    *projectOfferStartEffects(action, { call, put }) {
      let res = yield call(request.post, '/approval/startOfferProcess', {
        data: action.payload
      });
      return Promise.resolve(res.error_code);
    },
    /**
     * 修改接受时间(资料生成时间 || 入职指引接受时间 || 入职须知接受时间)
     */
    *modificAcceptTimeEffects(action, { call, put }) {
      let { staffId, type } = action.payload;
      let res = yield call(
        request.post,
        `/staff/updateReceiptTime?staffId=${staffId}&type=${type}`
      );
      return Promise.resolve(res.error_code);
    },
    /**
     * @description 简历信息编辑身份证号、电话号码校验
     */
    *checkoutIdCardPhoneEffects(action, { call, put }) {
      let res = yield call(request.post, '/talentPool/queryTalentPooltRepeatData', {
        data: action.payload,
      });
      return Promise.resolve({
        data: res.data,
        code: res.error_code,
        msg: res.error_msg
      });
    },
    /**
     * @description 查询员工或者部门
     */
    *employeeDepartmentEffects(action, { call, put }) {
      let res = yield call(request.get, '/dept/selectDeptStaffList', {
        params: action.payload
      });
      let result = []
      if (res.data) {
        result = res.data.records.map(item => ({
          key: item.staffId,
          name: item.staffName,
          sex: item.sex !== null ? (item.sex == 0 ? '男' : '女') : '--',
          phone: item.phoneNum,
          department: item.departmentName,
          staffType: item.staffType,
          number: item.staffNumber,
          dutyName: item.dutyName,
          transferEffectiveTime: item.transferEffectiveTime,
          staffJobName: item.staffJobName,
        }));
        return Promise.resolve({
          total: res.data.total,
          data: result
        })
      } else {
        return Promise.resolve({
          data: [],
          total: 0
        })
      }

    },
    /**
     * @description 部门树
     */
    *departmentTreeEffects(action, { call, put }) {
      let res = yield call(request.get, '/dept/deptTree', {
        params: action.payload
      });
      if (res && res.error_code !== 200) {
        notification.error({
          message: res ? res.error_msg : '请求错误'
        });
      }
      let result = processTree(res.data);
      return Promise.resolve(result);
    },
    /**
     * @description 保存流程节点
     */
    *saveFlowNodeEffects(action, { call, put }) {
      let res = yield call(saveFlowNode, action.payload);
      return Promise.resolve(res.error_code);
    }
  }
};
