import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import { saga } from 'dva';
import moment from 'moment';
import { formatMessage } from 'umi-plugin-react/locale';
import Authorized from '@/utils/Authorized';
import { menu } from '../defaultSettings';
import request from '@/utils/request';

const { delay } = saga;
const { check } = Authorized;

// 控制左侧菜单获取方式：
//   true 获取线上菜单,
//   false 使用本地 @/config/plugin.config.js 中的菜单，
// 默认不配置情况下会根据运行环境自动判断，本地开发环境就使用本地菜单，线上环境自动切换线上菜单
const isOnLineMenu = true;

/**
 * Conversion router to menu.
 */
function formatter(data, parentAuthority, parentName) {
  if (!data) {
    return undefined;
  }
  return data
    .map((item) => {
      // !item.name ||
      if (!item.path) {
        return null;
      }
      let locale = '';
      let name = item.title;
      if (!isOnLineMenu && item.name) {
        locale = 'menu';
        if (parentName && parentName !== '/') {
          locale = `${parentName}.${item.name}`;
        } else {
          locale = `menu.${item.name}`;
        }
        // if enableMenuLocale use item.name,
        // close menu international
        name = menu.disableLocal
          ? item.name
          : formatMessage({ id: locale, defaultMessage: item.name });
      }



      // const name = item.title;
      const result = {
        ...item,
        name,
        locale,
        authority: item.authority || parentAuthority
      };
      if (item.children) {
        const children = formatter(item.children, item.authority, locale);
        // Reduce memory usage
        result.children = children;
      }
      if (item.routes) {
        const children = formatter(item.routes, item.authority, locale);
        // Reduce memory usage
        result.children = children;
      }
      delete result.routes;
      return result;
    })
    .filter((item) => item);
}

const memoizeOneFormatter = memoizeOne(formatter, isEqual);

/**
 * get SubMenu or Item
 */
const getSubMenu = (item) => {
  // doc: add hideChildrenInMenu
  if (item.children && !item.hideChildrenInMenu && item.children.some((child) => child.name)) {
    return {
      ...item,
      children: filterMenuData(item.children) // eslint-disable-line
    };
  }
  return item;
};

/**
 * filter menuData
 */
const filterMenuData = (menuData) => {
  if (!menuData) {
    return [];
  }
  return menuData
    .filter((item) => item.name && !item.hideInMenu)
    .map((item) => check(item.authority, getSubMenu(item)))
    .filter((item) => item);
};

/**
 * 获取面包屑映射
 * @param {Object} menuData 菜单配置
 */
const getBreadcrumbNameMap = (menuData) => {
  if (!menuData) {
    return {};
  }
  const routerMap = {};

  const flattenMenuData = (data) => {
    data.forEach((menuItem) => {
      if (menuItem.children) {
        flattenMenuData(menuItem.children);
      }
      // Reduce memory usage
      routerMap[menuItem.path] = menuItem;
    });
  };
  flattenMenuData(menuData);
  return routerMap;
};

const memoizeOneGetBreadcrumbNameMap = memoizeOne(getBreadcrumbNameMap, isEqual);

/**
 * 获取服务器端返回路由菜单-当前登录用户的权限
 */
const getCurrentUserAuthority = async () => {
  const userInfo = window.localStorage.getItem('userInfo')
    ? JSON.parse(window.localStorage.getItem('userInfo'))
    : {};
  if (window.location.hash.includes('offer')) {
    return false;
  }
  if (!userInfo.staffId) {
    return false;
  }

  let menu = [];
  // /sysMenus/staffMenuList/+ userInfo.staffId,
  await request
    .post('/system/menu/getSystemMenuTree')
    .then((res) => {
      if (res && res.error_code === 200) {
        const result = !res.data ? [] : res.data;
        menu = result.treeMenus;
        window.localStorage.setItem('permissions', JSON.stringify(result.permissions));
        window.localStorage.setItem('authorized', JSON.stringify(result.treeMenus));
        // window.localStorage.setItem('permissionsMap', JSON.stringify(result.permissionsMap));
      }
    });
  return menu;
};

export default {
  namespace: 'menu',

  state: {
    menuData: [],
    routerData: [],
    breadcrumbNameMap: {}
  },

  effects: {
    *getMenuData({ payload }, { put, call }) {
      const { routes, authority, path } = payload;
      const datetime = new Date();

      // 获取线上菜单
      if (isOnLineMenu === true || process.env.NODE_ENV === 'production') {
        // 同步获取菜单
        let response = JSON.parse(window.localStorage.getItem('authorized'));
        if (!response) {
          response = yield call(getCurrentUserAuthority, payload);
        }
        yield call(delay, 1000);
        const originalMenuData = memoizeOneFormatter(response, authority, path);
        const menuData = filterMenuData(originalMenuData);
        const breadcrumbNameMap = memoizeOneGetBreadcrumbNameMap(originalMenuData);
        yield put({
          type: 'save',
          payload: { menuData, breadcrumbNameMap, routerData: response }
        });
      }
      // 获取本地菜单
      else if (isOnLineMenu === false || process.env.NODE_ENV === 'development') {
        // UMI自带写法
        const originalMenuData = memoizeOneFormatter(routes, authority, path);
        const menuData = filterMenuData(originalMenuData);
        const breadcrumbNameMap = memoizeOneGetBreadcrumbNameMap(originalMenuData);
        yield put({
          type: 'save',
          payload: { menuData, breadcrumbNameMap, routerData: routes }
        });
      }
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
};
