import lodash from 'lodash';
import {
  getPresentFlowDetail,
  updateFlowPriority,
  updateOrAddConditionFlow,
  releaseFlow,
  deleteFlow,
  processConditionDetail,
  getConditionParamList,
  selectCustomerList,
  getConditionValueList,
  addOrUpdateApproveCopyPeople,
  updateConditionParam,
  deleteConditionParam,
  getNewProcess
} from '@/services/approvalFlow';
import { ok, error } from '@/utils/modelUtils';

export default {
  namespace: 'approvalFlow',
  state: {},
  reducers: {},
  effects: {
    *getPresentFlowDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getPresentFlowDetail, { ...params, deploymentStatus: 1 });
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getCustomFlowDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getPresentFlowDetail, { ...params, deploymentStatus: 0 });
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *updateFlowPriority(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(updateFlowPriority, data);
        if (res && res.error_code === 200) {
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *updateOrAddConditionFlow(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(updateOrAddConditionFlow, data);
        if (res && res.error_code === 200) {
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *releaseFlow(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(releaseFlow, data);
        if (res && res.error_code === 200) {
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *deleteFlow(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(deleteFlow, data);
        if (res && res.error_code === 200) {
          return ok();
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *processConditionDetail(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(processConditionDetail, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getConditionParamList(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getConditionParamList, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *addOrUpdateApproveCopyPeople(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(addOrUpdateApproveCopyPeople, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *selectCustomerList(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(selectCustomerList, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getConditionValueList(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getConditionValueList, params);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *updateConditionParam(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(updateConditionParam, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *deleteConditionParam(action, { call }) {
      const data = action.payload;
      try {
        const res = yield call(deleteConditionParam, data);
        if (res && res.error_code === 200) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    },
    *getNewProcess(action, { call }) {
      const params = action.payload;
      try {
        const res = yield call(getNewProcess, params);
        if (res && res.error_code === 200 && res.data) {
          return ok(res);
        }
        return error(res);
      } catch (err) {
        return error(err);
      }
    }
  }
};
