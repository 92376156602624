import {
  extend
} from 'umi-request'
import {
  notification,
  message
} from 'antd'
import router from 'umi/router'
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';

// 全局配置
message.config({
  // top: 100,
  // duration: 2,
  maxCount: 1
})

function getCookie(name) {
  var arr = []
  var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)")
  arr = document.cookie.match(reg)
  return arr ? unescape(arr[2]) : null
}

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  302: '系统没权限',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户得到授权，但是访问是被禁止的。',
  403: '用户没有权限。',
  // 404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  404: '服务异常。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}

/**
 * 异常处理程序
 */
const errorHandler = error => {
  const {
    response = {}
  } = error;

  if (response == null) {
    window.location.href = '/#/user/login';
    return { error_msg: '服务不可用，请重新登陆' };
  }

  const errortext = codeMessage[response.status] || response.statusText
  const {
    status,
    url
  } = response;

  if (status === 200) {
    return true;
  }

  if(status === 404) {
    return { error_msg: errortext };
  }

  window.localStorage.removeItem('x-token');
  window.localStorage.removeItem('userInfo');
  window.localStorage.removeItem('authorized');
  window.localStorage.removeItem('buttonsAuthList');

  if (status === 302) {
    router.replace('/');
    return { error_msg: errortext };
  }

  if (status === 401) {
    window.location.href = '/#/user/login';
    return { error_msg: '登录过期' };
  }

  if (status === 403) {
    router.push('/exception/403');
    return { error_msg: errortext };
  }

  window.location.href = '/#/user/login';
  return { error_msg: '服务不可用，请重新登陆' };

}

/**
 * 配置request请求时的默认参数
 * https://github.com/umijs/umi-request
 */
const request = extend({
  prefix: '/api', // prefix
  errorHandler, // 默认错误处理
  credentials: 'include' // 默认请求是否带上cookie
  // headers: {
  //   // 'Content-Type': 'application/json',
  //   'x-token': window.localStorage.getItem('x-token')
  // }
})

// request拦截器, 改变url 或 options.
request.interceptors.request.use((url, options) => {
  options.headers.perms = options.headers.perms || (window.location.hash.replace('#', '')).split('?')[0];
  // let perms = "";
  // let headersPerms = options.headers.perms;
  // if (window.localStorage.getItem('x-token') && url != '/api/system/menu/getSystemMenuTree') {
  //   const hash = window.location.hash.replace('#', '');
  //   options.headers.perms = headersPerms ;
  // }
  return {
    url,
    options: {
      ...options,
      headers: {
        'x-token': window.localStorage.getItem('x-token'),
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        ...options.headers,
      }
    }
  }
})

// 请求结果拦截器
// request.interceptors.response.use(async (response) => {   
//   // const data = await response.clone().json();
//   if (response.status === 401) {
//     notification.error({
//       message: '未登录或登录已过期，请重新登录。'
//     });
//     router.push('/user/login');
//     return Promise.reject(response);
//   }
//   return Promise.resolve(response);
// })

export default request
