export default {
  'app.login.userName': 'Nome de usuário',
  'app.login.password': 'Sua senha',
  'app.login.message-invalid-credentials':
    'Nome de usuário ou senha inválidosd（admin/ant.design）',
  'app.login.message-invalid-verification-code': 'Código de verificação inválido',
  'app.login.tab-login-credentials': 'Credenciais',
  'app.login.tab-login-mobile': 'Telefone',
  'app.login.remember-me': 'Lembre-me',
  'app.login.forgot-password': 'Esqueceu sua senha?',
  'app.login.sign-in-with': 'Login com',
  'app.login.signup': 'Cadastre-se',
  'app.login.login': 'Login',
  'app.register.register': 'Cadastro',
  'app.register.get-verification-code': 'Recuperar código',
  'app.login.verification-code-warning':
    'This project is a demo project and will not actually send you a verification code. Please switch to the account password login interface and log in as prompted.',
  'app.register.sign-in': 'Já tem uma conta?',
  'app.register-result.msg': 'Conta：registrada em {email}',
  'app.register-result.activation-email':
    'Um email de ativação foi enviado para o seu email e é válido por 24 horas. Por favor entre no seu email e clique no link de ativação da conta.',
  'app.register-result.back-home': 'Voltar ao Início',
  'app.register-result.view-mailbox': 'Visualizar a caixa de email',
  'validation.email.required': 'Por favor insira seu email!',
  'validation.email.wrong-format': 'O email está errado!',
  'validation.userName.required': 'Por favor insira nome de usuário!',
  'validation.password.required': 'Por favor insira sua senha!',
  'validation.password.twice': 'As senhas não estão iguais!',
  'validation.password.strength.msg':
    'Por favor insira pelo menos 6 caracteres e não use senhas fáceis de adivinhar.',
  'validation.password.strength.strong': 'Força: forte',
  'validation.password.strength.medium': 'Força: média',
  'validation.password.strength.short': 'Força: curta',
  'validation.confirm-password.required': 'Por favor confirme sua senha!',
  'validation.phone-number.required': 'Por favor insira seu telefone!',
  'validation.phone-number.wrong-format': 'Formato de telefone errado!',
  'validation.verification-code.required': 'Por favor insira seu código de verificação!',
};
