import request from '@/utils/request';

export async function getRestPath(data) {
  return request('/approval/getRestPath', { method: 'POST', data });
}

export async function getApprovalProcess(params) {
  return request('/approval/getApprovalProcess', { method: 'GET', params });
}

/**
 * 获取补卡规则
 */
export async function fillPunchRule(params) {
  return request('/approval/fillPunchRule', { method: 'GET', params });
}

/**
 * 发起补卡审批
 */
export async function startFillPunchProcess(data) {
  return request('/approval/startFillPunchProcess', { method: 'POST', data });
}

/**
 * 获取当月补卡次数
 */
export async function getMonthCardNumber(params) {
  return request('/approval/getMonthCardNumber', { method: 'GET', params });
}

/**
 * 获取补卡审批详情
 */
export async function fillPunchDetail(params) {
  return request('/approval/fillPunchDetail', { method: 'GET', params });
}

/**
 * 完成补卡审批
 */
export async function completeFillPunchProcess(data) {
  return request('/approval/completeFillPunchProcess', { method: 'POST', data });
}

/**
 * 获取假期列表
 */
export async function getVisibilityHolidayList() {
  return request('/holiday/getVisibilityHolidayList', { method: 'GET' });
}

/**
 * 获取年假结余
 */
export async function getAnnualLeave(params) {
  return request('/holiday/annualLeave', { method: 'GET', params });
}

/**
 * 发起请假审批
 */
export async function startHolidayProcess(data) {
  return request('/approval/startHolidayProcess', { method: 'POST', data });
}

/**
 * 获取请假审批详情
 */
export async function getLeaveDetail(params) {
  return request('/holiday/getLeaveDetail', { method: 'GET', params });
}

/**
 * 获取员工请假规则
 */
export async function getStaffAttendanceRule(params) {
  return request('/holiday/getStaffLeaveRegulations', { method: 'GET', params });
}

/**
 * 校验日期可请假情况
 */
export async function getWhetherCanAskForLeave(data) {
  return request('/holiday/whetherCanAskForLeave', { method: 'POST', data });
}

/**
 * 获取月请假天数
 */
export async function getMonthHoliday(data) {
  return request('/holiday/getMonthHoliday', { method: 'POST', data });
}

/**
 * 获取加班规则
 */
export async function getOverTimeScope(params) {
  return request('/approval/overTimeScope', { method: 'GET', params });
}

/**
 * 获取加班审批详情
 */
export async function getOverTimeApprovalDetail(params) {
  return request('/approval/overTimeApprovalDetail', { method: 'GET', params });
}

/**
 * 发起加班审批
 */
export async function startOvertimeProcess(data) {
  return request('/approval/startOverTimeProcess', { method: 'POST', data });
}

/**
 * 完成加班审批
 */
export async function completeOvertimeProcess(data) {
  return request('/approval/overTimeApprovalDetail', { method: 'POST', data });
}

/**
 * 校验加班时间
 */
export function getOverTimeVerify(data) {
  return request('/approval/overTimeVerify', { method: 'POST', data });
}

/**
 * 获取当月加班时长（获取审批流程用）
 */
export function getMonthOvertime(data) {
  return request('/approval/getMonthOvertime', { method: 'POST', data });
}

/**
 * 获取项目人员个人信息
 */
export async function getStandard(params) {
  return request('/staff/standard/get', { method: 'GET', params });
}

/**
 * 获取客户职场
 */
export async function getProjectByContractId(params) {
  return request('/cusrCot/getProjectByContractId', { method: 'GET', params });
}

/**
 * 获取技能级别
 */
export async function getRateByProjectId(params) {
  return request('/cusrCot/getRateByProjectId', { method: 'GET', params });
}

/**
 * 调动发起页面提交按钮
 */
export async function startTransferMin(data) {
  return request('/approval/startTransferMin', { method: 'POST', data });
}

/**
 * 调动详情页面数据
 */
export async function getTransferProcessDetail(params) {
  return request('/approval/getTransferProcessDetail', { method: 'GET', params });
}

/**
 * 调动详情通过接口
 */
export async function completeTransferMin(data) {
  return request('/approval/completeTransferMin', { method: 'POST', data });
}

/**
 * 离职审批发起接口（以下放离职相关的接口）
 */
export async function startLeaveProcess(data) {
  return request('/approval/startLeaveProcess', { method: 'POST', data });
}

/**
 * 离职详情
 */
export async function getLeaveProcessDetail(params) {
  return request('/approval/getLeaveProcessDetail', { method: 'GET', params });
}

/**
 * 离职详情通过接口
 */
export async function completeTask(data) {
  return request('/approval/completeTask', { method: 'POST', data });
}

/**
 * 开具证明 提交接口
 */
export async function startProveProcessMin(data) {
  return request('/approval/startProveProcessMin', { method: 'POST', data });
}

/**
 * 开具证明 详情接口
 */
export async function proveProcessDetail(params) {
  return request('/approval/proveProcessDetail', { method: 'GET', params });
}

/**
 * 开具证明 通过接口
 */
export async function proveCompleteTaskMin(data) {
  return request('/approval/proveCompleteTaskMin', { method: 'POST', data });
}
/**
 * 设备申领 获取员工信息
 */
export async function getStandardStaff(params) {
  return request('/staff/standard/get', { method: 'GET', params });
}
/**
 * 设备申领 获取员工信息
 */
export async function getEquMsg(params) {
  return request('/assets/getEquMsg', { method: 'GET', params });
}
/**
 * 设备申领 发起接口
 */
export async function startApplyEquipment(data) {
  return request('/assets/startApplyEquipment', { method: 'POST', data });
}
