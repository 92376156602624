export default {
  'form.get-captcha': 'Get Captcha',
  'form.captcha.second': 'sec',
  'form.email.placeholder': 'Email',
  'form.password.placeholder': 'Senha',
  'form.confirm-password.placeholder': 'Confirme a senha',
  'form.phone-number.placeholder': 'Telefone',
  'form.verification-code.placeholder': 'Código de verificação',
  'form.optional': ' (opcional) ',
  'form.submit': 'Enviar',
  'form.save': 'Salvar',
  'form.title.label': 'Titulo',
  'form.title.placeholder': 'Dê o nome do input',
  'form.date.label': 'Período',
  'form.date.placeholder.start': 'Data Inicial',
  'form.date.placeholder.end': 'Data Final',
  'form.goal.label': 'Objetivos',
  'form.goal.placeholder': 'Por favor, digite os seus objetivos',
  'form.standard.label': 'Métricas',
  'form.standard.placeholder': 'Por favor, digite suas métricas',
  'form.client.label': 'Cliente',
  'form.client.label.tooltip': 'Target service object',
  'form.client.placeholder':
    'Por favor, descreva seu atendimento ao cliente, clientes internos diretamente @ Nome / número do trabalho',
  'form.invites.label': 'Convidados críticos',
  'form.invites.placeholder':
    'Por favor, dirija @ Nome / número do trabalho, você pode convidar até 5 pessoas',
  'form.weight.label': 'Peso',
  'form.weight.placeholder': 'Por favor, entre com o peso',
  'form.public.label': 'Revelação de objetivo',
  'form.public.label.help': 'Clientes e convidados são compartilhados por padrão',
  'form.public.radio.public': 'Publico',
  'form.public.radio.partially-public': 'Parcialmente publico',
  'form.public.radio.private': 'Privado',
  'form.publicUsers.placeholder': 'Aberto para',
  'form.publicUsers.option.A': 'Colega A',
  'form.publicUsers.option.B': 'Colega B',
  'form.publicUsers.option.C': 'Colega C',
};
