import request from '@/utils/request';

/**
 *获取审批配置列表
 */
export async function getSettingsList(params) {
  return request('/approval/getSettingsList', { method: 'GET', params });
}

/**
 *获取审批配置详情
 */
export async function getSettingDetail(params) {
  return request('/approval/getSettingsDetail', { method: 'GET', params });
}

/**
 *更新图标
 */
export async function setSettingIcon(data) {
  return request('/approval/setSettingIcon', { method: 'POST', data });
}

/**
 *更新启用状态
 */
export async function updateEnableState(data) {
  return request('/approval/settings/updateEnableState', { method: 'POST', data });
}

/**
 *更新配置基本数据
 */
export async function updateApprovalSettings(data) {
  return request('/approval/settings/updateApprovalSettings', { method: 'POST', data });
}

/**
 *获取配置可见范围
 */
export async function getApprovalVisibleScope(params) {
  return request('/approval/getApprovalVisibleScope', { method: 'GET', params });
}

/**
 *更新配置可见范围
 */
export async function updateApprovalVisibleScope(data) {
  return request('/approval/updateApprovalVisibleScope', { method: 'POST', data });
}

/**
 *获取审批默认处理人
 */
export async function getApprovalByDefaultPeople() {
  return request('/approval/getApprovalByDefaultPeople', { method: 'GET' });
}

/**
 *更新审批默认处理人
 */
export async function updateApprovalByDefaultPeople(data) {
  return request('/approval/updateApprovalByDefaultPeople', { method: 'POST', data });
}

/**
 * 获取审批配置操作记录
 */
export async function getSettingLogList(params) {
  return request('/approval/getSettingLogList', { method: 'GET', params });
}

/**
 * 获取审批类型字典
 */
export async function getSettingLogOperationTypes() {
  return request('/approval/getSettingLogOperTypeList', { method: 'GET' });
}
